import React, { useState, useEffect } from 'react';
import { Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import TabGambar from './TabGambar';
import TabFile from './TabFile';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { Formik } from 'formik';

import Axios from 'axios';
import { DataStatus, InfoItemHorizontal } from 'components';
import { PurchaseOrderApi } from 'api';

export const ModalSeleksiVendor = ({ setModalConfig, modalConfig }) => {
  console.log('test', modalConfig?.data);

  return (
    <>
      <Modal
        show={modalConfig.show}
        onHide={() => setModalConfig({ id: '', show: false })}
        size="lg"
      >
        <Modal.Header closeButton>Detail Penawaran Vendor</Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="6">
              <InfoItemHorizontal
                label="Entitas Aset"
                text={modalConfig?.data?.nama_entitas_aset}
              />
              <InfoItemHorizontal
                label="Grup Aset"
                text={modalConfig?.data?.nama_grup_aset}
              />
              <InfoItemHorizontal
                label="Kategori Aset"
                text={modalConfig?.data?.nama_kategori_aset}
              />
              <InfoItemHorizontal
                label="Jenis Aset"
                text={modalConfig?.data?.nama_jenis_aset}
              />
              <InfoItemHorizontal
                label="Nama Aset"
                text={modalConfig?.data?.nama_aset}
              />
            </Col>
            <Col lg="6">
              <InfoItemHorizontal
                label="Keperluan"
                text={modalConfig?.data?.keperluan}
              />
              <InfoItemHorizontal
                label="Metode Pengadaan"
                text={modalConfig?.data?.metode_pengadaan}
              />
              <InfoItemHorizontal
                label="Unit Organisasi"
                text={modalConfig?.data?.nama_unit_organisasi}
              />
              <InfoItemHorizontal
                label="Keterangan"
                text={modalConfig?.data?.keterangan}
              />
            </Col>
          </Row>
          <Formik
            initialValues={{
              ...modalConfig?.data,
              file: modalConfig?.data?.file?.map((val) => val?.path_file),
            }}
          >
            <Card className="mt-3">
              <Tab.Container defaultActiveKey="tabGambar">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabFile">File</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Tab.Content>
                  <Tab.Pane eventKey="tabGambar">
                    <div className="m-2">
                      <TabGambar readOnly />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabFile">
                    <div className="m-2">
                      <TabFile readOnly />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
