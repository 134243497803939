// React
import React, { useState, useEffect } from 'react'

// Form
import { Formik } from 'formik'

// Components
import { DatePicker, FilterModal, SelectSearch } from 'components'
import { DateConvert } from 'utilities'

// API
import Axios from "axios"
import { TransferSubkonJobMixApi } from 'api'

// View Functions
import { 
	mappingData, mappingDataItem 
} from '../../functions'

const ModalFilter = ({ toggle, data, setData, buaso }) => {
	// Variables
	const option = { label: 'Semua Data', value: undefined }

	// States
	const [dropdownFilter, setDropdownFilter] = useState({
		[buaso]: [option],
		vendor: [option]
	  })
	const [loading, setLoading] = useState(true)

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		Axios.all([
			TransferSubkonJobMixApi.dropdown(buaso, buaso),
			TransferSubkonJobMixApi.dropdown(buaso, 'vendor')
		])
		.then(Axios.spread((buasoData, vendor) => {
			// eslint-disable-next-line
			setDropdownFilter({
				[buaso]: [option, ...mappingDataItem(buasoData, 'item_buaso')],
				vendor: [option, ...mappingData(vendor, 'vendor')],
			})
		}))
		.finally(() => {
			setLoading(false)
		})

		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		[`tgl_transfer_produksi_${buaso}_awal`]: data[`tgl_transfer_produksi_${buaso}_awal`],
		[`tgl_transfer_produksi_${buaso}_akhir`]: data[`tgl_transfer_produksi_${buaso}_akhir`],
		[`tgl_permintaan_produksi_${buaso}_awal`]: data[`tgl_permintaan_produksi_${buaso}_awal`],
		[`tgl_permintaan_produksi_${buaso}_akhir`]: data[`tgl_permintaan_produksi_${buaso}_akhir`],
		id_item_buaso: data.id_item_buaso,
		id_vendor: data.id_vendor
	}
	const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive,
			page: 1
		})

		toggle()
	}

	// HANDLE CHANGE
	const onResetButtonClick = setValues => {
		setLoading(true)

		const myPromise = new Promise(resolve => {
			setValues({
				[`tgl_transfer_produksi_${buaso}_awal`]: undefined,
				[`tgl_transfer_produksi_${buaso}_akhir`]: undefined,
				[`tgl_permintaan_produksi_${buaso}_awal`]: undefined,
				[`tgl_permintaan_produksi_${buaso}_akhir`]: undefined,
				id_item_buaso: undefined,
				id_vendor: undefined
			})

			setTimeout(() => resolve(), 300)
		})

		myPromise.then(() => setLoading(false))
	}

	const onTanggalChange = (dates, values, setValues, value_awal, value_akhir) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null
	
		setValues({
		  ...values,
		  [value_awal]: startDate,
		  [value_akhir]: endDate
		})
	  }

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
		>	
			{({values, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					isToggle
					toggleModal={toggle}
					onResetButtonClick={() => onResetButtonClick(setValues)}
					onFilterButtonClick={handleSubmit}
				>
					<DatePicker 
						selectsRange
						label={<div className='text-capitalize'>Tgl. Transfer {buaso} Produksi</div>}
						placeholderText={`Pilih Tgl. Transfer ${buaso} Produksi`}
						startDate={values[`tgl_transfer_produksi_${buaso}_awal`] ? new Date(values[`tgl_transfer_produksi_${buaso}_awal`]) : ''}
						endDate={values[`tgl_transfer_produksi_${buaso}_akhir`] ? new Date(values[`tgl_transfer_produksi_${buaso}_akhir`]) : ''}
						onChange={dates => onTanggalChange(dates, values, setValues, `tgl_transfer_produksi_${buaso}_awal`, `tgl_transfer_produksi_${buaso}_akhir`)}
						monthsShown={2}
					/>

					<DatePicker 
						selectsRange
						label={<div className='text-capitalize'>Tgl. Permintaan {buaso} Produksi</div>}
						placeholderText={`Pilih Tgl. Permintaan ${buaso} Produksi`}
						startDate={values[`tgl_permintaan_produksi_${buaso}_awal`] ? new Date(values[`tgl_permintaan_produksi_${buaso}_awal`]) : ''}
						endDate={values[`tgl_permintaan_produksi_${buaso}_akhir`] ? new Date(values[`tgl_permintaan_produksi_${buaso}_akhir`]) : ''}
						onChange={dates => onTanggalChange(dates, values, setValues, `tgl_permintaan_produksi_${buaso}_awal`, `tgl_permintaan_produksi_${buaso}_akhir`)}
						monthsShown={2}
					/>

					<SelectSearch 
						key="1"
						label={<div className='text-capitalize'>Item {buaso}</div>}
						placeholderText={`Pilih Item ${buaso}`}
						defaultValue={dropdownFilter[buaso].find(val => val.value === values.id_item_buaso)}
						option={dropdownFilter[buaso]}
						onChange={val => setFieldValue('id_item_buaso', val.value)}
						loading={loading}
					/>

					<SelectSearch 
						key="2"
						label="Vendor"
						placeholder="Pilih Vendor"
						defaultValue={dropdownFilter.vendor.find(val => val.value === values.id_vendor)}
						option={dropdownFilter.vendor}
						onChange={val => setFieldValue('id_vendor', val.value)}
						loading={loading}
					/>
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilter