export const formInitialValues = (data) => ({
  tgl_purchase_order: data?.tgl_purchase_order,
  no_purchase_order: data?.no_purchase_order,
  alamat_tujuan_pengiriman: data?.alamat_tujuan_pengiriman,
  nama_vendor: data?.nama_vendor,
  contact_person: data?.contact_person,
  catatan: data?.catatan,
  tgl_close_purchase_order: data?.tgl_close_purchase_order,
  no_close_purchase_order: data?.no_close_purchase_order,
  id_purchase_order: data?.id_purchase_order,
  detail: data?.barang,
  keterangan: data?.keterangan,
});
