import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Input, SelectSearch, ActionButton, TextArea } from "../../../../components";

import { VendorApi, ListJenisVendorApi } from "../../../../api";

const FormRegisterVendor = ({
  setPage,
  setIdVendor,
  dataKode,
  jenisVendor,
  setJenisVendor,
  setAlertHandler,
}) => {
  const [formRegisterVendor, setFormRegisterVendor] = useState({});
  const [dataBank, setDataBank] = useState([]);
  const [dataProvinsi, setDataProvinsi] = useState([]);
  const [allDataVendor, setAllDataVendor] = useState([]);
  const [data, setData] = useState({
    kabupaten: [],
    kecamatan: [],
    desa: [],
  });
  const [isLoading, setIsLoading] = useState({
    kabupaten: false,
    kecamatan: false,
    desa: false,
  });

  const getData = () => {
    setIsLoading(true);

    Axios.all([VendorApi.getBank(), VendorApi.getProvinsi(), VendorApi.getAllData()])
      .then(
        Axios.spread((bank, prov, all) => {
          setDataBank(bank.data.data);
          setDataProvinsi(prov.data.data);
          setAllDataVendor(all.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  const getDataKabupaten = (id) => {
    setIsLoading({
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    VendorApi.getKabupaten(id)
      .then((res) =>
        setData({
          ...data,
          kabupaten: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoading({
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataKecamatan = (id) => {
    setIsLoading({
      ...isLoading,
      kecamatan: true,
      desa: true,
    });

    VendorApi.getKecamatan(id)
      .then((res) =>
        setData({
          ...data,
          kecamatan: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoading({
          ...isLoading,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataDesa = (id) => {
    setIsLoading({
      ...isLoading,
      desa: true,
    });

    VendorApi.getDesa(id)
      .then((res) =>
        setData({
          ...data,
          desa: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoading({
          ...isLoading,
          desa: false,
        })
      );
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  const formInitialValues = {
    username: "",
    kode_vendor: "",
    nama_vendor: "",
    jenis_vendor: [],
    nama_pimpinan_perusahaan: "",
    contact_person: "",
    telepon: "",
    email: "",
    website: "",
    media_sosial: "",
    password: "",
    konfirmasi_password: "",
    alamat_vendor: "",
    id_provinsi: "",
    id_kabupaten: "",
    id_kecamatan: "",
    id_desa: "",
    kode_pos: "",
    no_rekening: "",
    kode_bank: "",
    atas_nama: "",
  };

  const formValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Masukkan Username Vendor")
      .test("Username telah digunakan", function (value) {
        const { path, createError } = this;

        return VendorApi.singleUsername(value).then((res) => {
          if (res.data.status) {
            return createError({
              path,
              message: "Username telah digunakan" ?? "UNIQUE_ERROR_MESSAGE",
            });
          } else {
            return true;
          }
        });
      }),
    nama_vendor: Yup.string().required("Masukkan Nama Vendor"),
    contact_person: Yup.string().required("Masukkan Contact Person"),
    jenis_vendor: Yup.array().min(1, "Pilih Jenis Vendor"),
    nama_pimpinan_perusahaan: Yup.string().required("Masukkan Nama Pimpinan Perusahaan"),
    telepon: Yup.number("Hanya boleh diisi dengan angka").required("Masukkan Telepon"),
    email: Yup.string().email("Gunakan format Email yang benar"),
    website: Yup.string().url("Gunakan format URL yang benar"),
    password: Yup.string()
      .required("Masukkan Password")
      .oneOf([Yup.ref("konfirmasi_password")], "Password belum sama dengan Konfirmasi Password"),
    konfirmasi_password: Yup.string()
      .required("Masukkan Konfirmasi Password")
      .oneOf([Yup.ref("password")], "Konfirmasi Password belum sama dengan Password"),
    alamat_vendor: Yup.string().required("Masukkan Alamat Vendor"),
    id_provinsi: Yup.string().required("Pilih Provinsi"),
    id_kabupaten: Yup.string().required("Pilih Kabupaten"),
    id_kecamatan: Yup.string().required("Pilih Kecamatan"),
    id_desa: Yup.string().required("Pilih Desa"),
    kode_pos: Yup.number("Hanya boleh diisi dengan angka"),
    no_rekening: Yup.number("Hanya boleh diisi dengan angka").required("Masukkan No. Rekening"),
    kode_bank: Yup.string().required("Pilih Kode Bank"),
    atas_nama: Yup.string().required("Masukkan Nama Nasabah"),
    kode_vendor: Yup.string()
      .required("Masukkan Kode Vendor")
      .test("Kode Telah Di Gunakan", function () {
        const { path, createError } = this;
        const value = this.parent.kode_vendor;
        const check = allDataVendor.filter((res) => res.kode_vendor === value);

        console.log(check);
        if (check.length > 0) {
          return createError({
            path,
            message: "Kode Telah Di Gunakan" ?? "UNIQUE_ERROR_MESSAGE",
          });
        } else {
          return true;
        }
      }),
  });

  const formSubmitHandler = (values) => {
    setFormRegisterVendor(values);

    let listJenisValues = {};

    VendorApi.create(values)
      .then((res) => {
        listJenisValues = {
          id_vendor: res.data.data.id_vendor,
          jenis_vendor: values.jenis_vendor,
        };

        setIdVendor(res.data.data.id_vendor);
      })
      .catch((err) =>
        setAlertHandler({
          show: true,
          variant: "danger",
          text: `Tambah Vendor gagal! (${err})`,
        })
      )
      .finally(() => {
        ListJenisVendorApi.create(listJenisValues)
          .then((res) => {
            setJenisVendor(res.data.data);
          })
          .catch((err) =>
            setAlertHandler({
              show: true,
              variant: "danger",
              text: `Tambah List Jenis Vendor gagal! (${err})`,
            })
          )
          .finally(() => {
            setPage(2);
          });
      });
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit }) => (
        <form className="py-2" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Input
                label="Username"
                placeholder="Masukkan Username Vendor"
                name="username"
                value={values.username}
                error={errors.username && touched.username && true}
                errorText={errors.username}
                onChange={handleChange}
              />

              <Input
                label="Kode Vendor"
                name="kode_vendor"
                value={values.kode_vendor}
                placeholder="Masukan Kode Vendor"
                // readOnly={true}
                onChange={handleChange}
                error={errors.kode_vendor && touched.kode_vendor && true}
                errorText={errors.kode_vendor}
              />

              <Input
                label="Nama Vendor"
                placeholder="Masukkan Nama Vendor"
                name="nama_vendor"
                value={values.nama_vendor}
                error={errors.nama_vendor && touched.nama_vendor && true}
                errorText={errors.nama_vendor}
                onChange={handleChange}
              />

              <div>
                <small>Jenis Vendor</small>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="jenis_vendor"
                    value="Supplier"
                    className={
                      "form-check-input " +
                      (errors.jenis_vendor && touched.jenis_vendor ? " is-invalid" : "")
                    }
                  />
                  <label htmlFor="jenis_vendor" className="form-check-label">
                    Supplier
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="jenis_vendor"
                    value="Mandor"
                    className={
                      "form-check-input " +
                      (errors.jenis_vendor && touched.jenis_vendor ? " is-invalid" : "")
                    }
                  />
                  <label htmlFor="jenis_vendor" className="form-check-label">
                    Mandor
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="jenis_vendor"
                    value="Subcon"
                    className={
                      "form-check-input " +
                      (errors.jenis_vendor && touched.jenis_vendor ? " is-invalid" : "")
                    }
                  />
                  <label htmlFor="jenis_vendor" className="form-check-label">
                    Subcon
                  </label>
                  <ErrorMessage name="jenis_vendor" component="div" className="invalid-feedback" />
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="jenis_vendor"
                    value="Lainnya"
                    className={
                      "form-check-input " +
                      (errors.jenis_vendor && touched.jenis_vendor ? " is-invalid" : "")
                    }
                  />
                  <label htmlFor="jenis_vendor" className="form-check-label">
                    Lainnya
                  </label>
                  <ErrorMessage name="jenis_vendor" component="div" className="invalid-feedback" />
                </div>
              </div>

              <Input
                label="Nama Pimpinan"
                placeholder="Masukkan Nama Pimpinan Perusahaan"
                name="nama_pimpinan_perusahaan"
                value={values.nama_pimpinan_perusahaan}
                error={errors.nama_pimpinan_perusahaan && touched.nama_pimpinan_perusahaan && true}
                errorText={errors.nama_pimpinan_perusahaan}
                onChange={handleChange}
              />

              <Input
                label="Contact Person"
                placeholder="Masukkan Contact Person"
                name="contact_person"
                value={values.contact_person}
                error={errors.contact_person && touched.contact_person && true}
                errorText={errors.contact_person}
                onChange={handleChange}
              />

              <Input
                label="Telepon"
                placeholder="Masukkan Telepon"
                name="telepon"
                value={values.telepon}
                error={errors.telepon && touched.telepon && true}
                errorText={errors.telepon}
                onChange={handleChange}
              />

              <Input
                label="Email"
                type="email"
                placeholder="Masukkan Email"
                name="email"
                value={values.email}
                error={errors.email && touched.email && true}
                errorText={errors.email}
                onChange={handleChange}
              />

              <Input
                label="Website"
                placeholder="Masukkan Website"
                name="website"
                value={values.website}
                error={errors.website && touched.website && true}
                errorText={errors.website}
                onChange={handleChange}
              />

              <TextArea
                label="Media Sosial"
                type="text"
                name="media_sosial"
                placeholder="Masukkan Media Sosial"
                value={values.media_sosial}
                onChange={handleChange}
                error={errors.media_sosial && touched.media_sosial && true}
                errorText={errors.media_sosial}
                rows={4}
              />
            </Col>

            <Col>
              <Input
                label="Password"
                placeholder="Masukkan Password"
                type="password"
                name="password"
                value={values.password}
                error={errors.password && touched.password && true}
                errorText={errors.password}
                onChange={handleChange}
              />

              <Input
                label="Konfirmasi Password"
                placeholder="Masukkan Konfirmasi Password"
                type="password"
                name="konfirmasi_password"
                value={values.konfirmasi_password}
                error={errors.konfirmasi_password && touched.konfirmasi_password && true}
                errorText={errors.konfirmasi_password}
                onChange={handleChange}
              />

              <Input
                label="Alamat Vendor"
                placeholder="Masukkan Alamat Vendor"
                name="alamat_vendor"
                value={values.alamat_vendor}
                error={errors.alamat_vendor && touched.alamat_vendor && true}
                errorText={errors.alamat_vendor}
                onChange={handleChange}
              />

              <SelectSearch
                label="Provinsi"
                name="id_provinsi"
                // onFocus={getProvinsi}
                defaultValue={{
                  value: "",
                  label: "Pilih Provinsi",
                }}
                option={dataProvinsi.map((val) => {
                  return {
                    value: val.id_provinsi,
                    label: val.nama_provinsi,
                  };
                })}
                onChange={(val) => {
                  setFieldValue("id_provinsi", val.value);
                  setFieldValue("nama_provinsi", val.label);
                  setFieldValue("id_kabupaten", "");
                  setFieldValue("id_kecamatan", "");
                  setFieldValue("id_desa", "");
                  getDataKabupaten(val.value);
                }}
                error={errors.id_provinsi && touched.id_provinsi && true}
                errorText={errors.id_provinsi && touched.id_provinsi && errors.id_provinsi}
              />

              <SelectSearch
                label="Kabupaten"
                name="id_kabupaten"
                defaultValue={{
                  value: "",
                  label: "Pilih Kabupaten",
                }}
                option={data.kabupaten.map((val) => {
                  return {
                    value: val.id_kabupaten,
                    label: val.nama_kabupaten,
                  };
                })}
                onChange={(val) => {
                  setFieldValue("id_kabupaten", val.value);
                  setFieldValue("nama_kabupaten", val.label);
                  setFieldValue("id_kecamatan", "");
                  setFieldValue("id_desa", "");
                  getDataKecamatan(val.value);
                }}
                error={errors.id_kabupaten && touched.id_kabupaten && true}
                errorText={errors.id_kabupaten && touched.id_kabupaten && errors.id_kabupaten}
                loading={isLoading.kabupaten}
                isDisabled={values.id_provinsi ? false : true}
              />

              <SelectSearch
                label="Kecamatan"
                name="id_kecamatan"
                defaultValue={{
                  value: "",
                  label: "Pilih Kecamatan",
                }}
                option={data.kecamatan.map((val) => {
                  return {
                    value: val.id_kecamatan,
                    label: val.nama_kecamatan,
                  };
                })}
                onChange={(val) => {
                  setFieldValue("id_kecamatan", val.value);
                  setFieldValue("nama_kecamatan", val.label);
                  setFieldValue("id_desa", "");
                  getDataDesa(val.value);
                }}
                error={errors.id_kecamatan && touched.id_kecamatan && true}
                errorText={errors.id_kecamatan && touched.id_kecamatan && errors.id_kecamatan}
                loading={isLoading.kecamatan}
                isDisabled={values.id_kabupaten ? false : true}
              />

              <SelectSearch
                label="Desa"
                name="id_desa"
                defaultValue={{
                  value: "",
                  label: "Pilih Desa",
                }}
                option={data.desa.map((val) => {
                  return {
                    value: val.id_desa,
                    label: val.nama_desa,
                  };
                })}
                onChange={(val) => {
                  setFieldValue("id_desa", val.value);
                  setFieldValue("nama_desa", val.label);
                }}
                error={errors.id_desa && touched.id_desa && true}
                errorText={errors.id_desa && touched.id_desa && errors.id_desa}
                loading={isLoading.desa}
                isDisabled={values.id_kecamatan ? false : true}
              />

              <Input
                label="Kode Pos"
                placeholder="Masukkan Kode Pos"
                name="kode_pos"
                value={values.kode_pos}
                error={errors.kode_pos && touched.kode_pos && true}
                errorText={errors.kode_pos}
                onChange={handleChange}
              />

              <Input
                label="No. Rekening"
                placeholder="Masukkan No. Rekening"
                name="no_rekening"
                value={values.no_rekening}
                error={errors.no_rekening && touched.no_rekening && true}
                errorText={errors.no_rekening}
                onChange={handleChange}
              />

              <SelectSearch
                label="Bank"
                // onFocus={getBank}
                name="kode_bank"
                defaultValue={{
                  value: "",
                  label: "Pilih Bank",
                }}
                option={dataBank.map((val) => {
                  return {
                    value: val.kode_bank,
                    label: val.nama_bank,
                  };
                })}
                onChange={(val) => {
                  setFieldValue("kode_bank", val.value);
                  setFieldValue("nama_bank", val.label);
                }}
                error={errors.kode_bank && touched.kode_bank && true}
                errorText={errors.kode_bank && touched.kode_bank && errors.kode_bank}
              />

              <Input
                label="Atas Nama"
                placeholder="Masukkan Nama Nasabah"
                name="atas_nama"
                onChange={handleChange}
                error={errors.atas_nama && touched.atas_nama && true}
                errorText={errors.atas_nama && touched.atas_nama && errors.atas_nama}
              />
            </Col>
          </Row>
          <Input
            label="Status Vendor"
            placeholder="Masukkan Status Vendor"
            onChange={handleChange}
            readOnly={true}
            value="Calon Vendor"
          />
          <Input
            label="Status Tender"
            placeholder="Masukkan Status Tender"
            onChange={handleChange}
            readOnly={true}
            value="Belum Pernah Menang Tender"
          />
          <div className="d-flex justify-content-end pt-3">
            <ActionButton
              type="submit"
              variant="primary"
              text="Simpan Vendor"
              loading={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FormRegisterVendor;
