import React, { useState, useRef, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from '../../../../components';
import {
  RupiahConvert,
  DateConvert,
  DecimalConvert,
} from '../../../../utilities';
import { PurchaseOrderAsetApi } from '../../../../api';
import PrintPurchaseOrder from './PrintPurchaseOrder';
import { IoEyeOutline } from 'react-icons/io5';
import { ModalSeleksiVendor } from '../Section';

const DetailPurchaseOrderAset = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const componentRef = useRef();
  const location = useLocation();
  const routerState = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({});
  const [dataPODetail, setDataPODetail] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderAsetApi.getSingle({ id_purchase_order_aset: id })
      .then(({ data }) => {
        const dataPO = data.data;
        const dataDetail = data?.data?.detail;

        setDataPurchaseOrder(dataPO);

        setDataPODetail(dataDetail);
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat('id-ID').format(checkConvert);
  };

  const PageContent = () => {
    const dataPengaju = dataPurchaseOrder?.stakeholder
      ? dataPurchaseOrder.stakeholder
      : [];

    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td>
          <div className="pl-4 pr-2">: </div>
        </td>
        <td>{value}</td>
      </tr>
    );

    const InfoItems = ({ title, value }) => (
      <tr>
        <td width={200}>{title}</td>
        <td className="pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;
    let nilaiDP = 0;
    let totalBiayaAngkut = 0;
    let grandTotal = 0;

    dataPODetail.map((val, index) => {
      const jumlah = parseFloat(val.harga_kesepakatan);
      const jumlahBiayaAngkut = parseFloat(
        val.harga_kesepakatan_biaya_angkut ?? 0
      );

      totalBiayaAngkut = totalBiayaAngkut + jumlahBiayaAngkut;
      total = total + jumlah;
      nilaiDiskon = dataPurchaseOrder?.diskon
        ? parseFloat(dataPurchaseOrder?.diskon) === 0
          ? 0
          : parseFloat((dataPurchaseOrder?.diskon / 100) * total)
        : 0;
      setelahDiskon = parseFloat(total - nilaiDiskon);
      nilaiPPN = dataPurchaseOrder?.ppn
        ? parseFloat(dataPurchaseOrder?.ppn) === 0
          ? 0
          : parseFloat((dataPurchaseOrder?.ppn / 100) * setelahDiskon)
        : 0;
      setelahPPN = parseFloat(setelahDiskon + nilaiPPN);

      grandTotal = setelahPPN + totalBiayaAngkut;
      nilaiDP = dataPurchaseOrder?.dp
        ? parseFloat(dataPurchaseOrder?.dp) === 0
          ? 0
          : parseFloat((dataPurchaseOrder?.dp / 100) * grandTotal)
        : 0;
    });

    const CatatanSection = () => (
      <div>
        <div className="mt-3 mb-3">
          Pesanan ini selambat-lambatnya telah sampai pada :
        </div>
        <Row>
          <Col>
            <table>
              <tbody>
                <InfoItems
                  title="Tanggal"
                  value={
                    dataPurchaseOrder.tgl_pengiriman
                      ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman))
                          .detail
                      : '-'
                  }
                />
                <InfoItems
                  title="Pembayaran"
                  value={dataPurchaseOrder.pembayaran}
                />
                <InfoItems
                  title="Dikirim ke"
                  value={dataPurchaseOrder.alamat_tujuan}
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <table>
                <tbody>
                  <InfoItems
                    title="Sub Total"
                    value={
                      RupiahConvert(parseFloat(total).toString()).getWithComa
                    }
                  />
                  <InfoItems
                    title={`Diskon ${
                      DecimalConvert(dataPurchaseOrder.diskon ?? 0).getWithComa
                    } %`}
                    value={RupiahConvert(nilaiDiskon.toString()).getWithComa}
                  />
                  <InfoItems
                    title="Harga Setelah DIskon"
                    value={
                      RupiahConvert(parseFloat(setelahDiskon).toString())
                        .getWithComa
                    }
                  />
                  <InfoItems
                    title={`PPN ${
                      DecimalConvert(dataPurchaseOrder.ppn ?? 0).getWithComa
                    } %`}
                    value={RupiahConvert(nilaiPPN.toString()).getWithComa}
                  />
                  <InfoItems
                    title="Harga Setelah Pajak"
                    value={
                      RupiahConvert(parseFloat(setelahPPN).toString())
                        .getWithComa
                    }
                  />
                  <InfoItems
                    title="Biaya Angkut"
                    value={
                      RupiahConvert(parseFloat(totalBiayaAngkut).toString())
                        .getWithComa
                    }
                  />
                  <InfoItems
                    title="Grand Total"
                    value={
                      RupiahConvert(parseFloat(grandTotal).toString())
                        .getWithComa
                    }
                  />
                  <InfoItems
                    title={`DP ${
                      DecimalConvert(dataPurchaseOrder.dp ?? 0).getWithComa
                    } %`}
                    value={
                      RupiahConvert(parseFloat(nilaiDP).toString()).getWithComa
                    }
                  />
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    );

    // console.log(dataPurchaseOrder);

    const InfoSection = () => {
      return (
        <>
          <Row className="mb-3">
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="Tgl. Purchase Order Aset"
                    value={
                      dataPurchaseOrder.tgl_purchase_order_aset
                        ? DateConvert(
                            new Date(dataPurchaseOrder.tgl_purchase_order_aset)
                          ).detail
                        : '-'
                    }
                  />
                  <InfoItem
                    title="No. Purchase Order Aset"
                    value={
                      dataPurchaseOrder.no_purchase_order_aset
                        ? dataPurchaseOrder.no_purchase_order_aset
                        : '-'
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <InfoItem title="Kepada" />
                  <span>
                    {dataPurchaseOrder.nama_vendor
                      ? dataPurchaseOrder.nama_vendor
                      : '-'}
                  </span>
                  <br />
                  <span>
                    {dataPurchaseOrder.alamat_vendor
                      ? dataPurchaseOrder.alamat_vendor
                      : '-'}
                  </span>
                  <br />
                  <span>
                    {dataPurchaseOrder.contact_person
                      ? dataPurchaseOrder.contact_person
                      : '-'}
                  </span>
                </tbody>
              </table>
            </Col>
          </Row>
        </>
      );
    };

    const ListItemPurchaseOrderSection = () => {
      const [modalDetailSelesiVendorDetail, setModalDetailSelesiVendorDetail] =
        useState({ id: '', show: false, data: {} });

      return (
        <>
          {modalDetailSelesiVendorDetail?.show && (
            <ModalSeleksiVendor
              setModalConfig={setModalDetailSelesiVendorDetail}
              modalConfig={modalDetailSelesiVendorDetail}
            />
          )}
          <div className="py-2 pl-1">
            <span>Kami tempatkan order pembelian dengan sbb :</span>
          </div>
          {dataPODetail ? (
            dataPODetail.length > 0 ? (
              <>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No.</ThFixed>
                      <Th>Nama Item Aset</Th>
                      <Th>Keperluan</Th>
                      <Th>Harga Kesepakatan</Th>
                      <Th>Vendor Angkut</Th>
                      <Th>Harga Kesepakatan Biaya Angkut</Th>
                      <Th>Jumlah</Th>
                      <Th>Aksi</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {dataPODetail.map((val, index) => {
                      const jumlah = parseFloat(val.harga_kesepakatan);

                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <Td>
                            <div style={{ width: '200px' }}>
                              {val.nama_aset}
                            </div>
                          </Td>
                          <Td className="text-capitalize">
                            {val.keperluan ?? '-'}
                          </Td>
                          <Td className="text-right">
                            {
                              RupiahConvert(
                                parseFloat(
                                  val.harga_kesepakatan ?? 0
                                ).toString()
                              ).getWithComa
                            }
                          </Td>
                          <Td>{val?.nama_vendor_angkut ?? '-'}</Td>
                          <Td className="text-right">
                            {
                              RupiahConvert(
                                parseFloat(
                                  val?.harga_kesepakatan_biaya_angkut ?? 0
                                ).toString()
                              ).getWithComa
                            }
                          </Td>
                          <Td className="text-right">
                            {
                              RupiahConvert(parseFloat(jumlah).toString())
                                .getWithComa
                            }
                          </Td>
                          <Td className="text-center">
                            <ActionButton
                              size="sm"
                              className="mr-1"
                              text={<IoEyeOutline />}
                              onClick={() => {
                                setModalDetailSelesiVendorDetail({
                                  id: val.id_seleksi_vendor,
                                  show: true,
                                  data: val,
                                });
                              }}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </TBody>
                </Table>
                <CatatanSection />
                <div>
                  <span>Keterangan :</span>
                  <p>{dataPurchaseOrder.keterangan}</p>
                </div>
              </>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Purchase Order Aset</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataPengaju.map((val, index) => (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval === 'VER' ||
                      val.status_approval === 'REV'
                        ? `Pemeriksa ${
                            val.approval_level !== '0' ? val.approval_level : ''
                          }`
                        : val.status_approval === 'APP'
                        ? 'Pengesah'
                        : val.status_approval === 'PEN'
                        ? 'Pembuat'
                        : 'Di Tolak Oleh'
                    }
                    value1={val.nama_karyawan ?? '-'}
                    title2="Catatan"
                    value2={val.catatan ?? '-'}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <div className="p-4">
        <InfoSection />
        <ListItemPurchaseOrderSection />
        <hr />
        <FormCard />
      </div>
    );
  };

  useEffect(() => {
    setNavbarTitle('Detail Purchase Order Aset');
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Data Purchase Order Aset</b>
        <div className="d-flex align-items-center">
          {/* {!isPageLoading && (
            <ReactToPrint
              trigger={() => (
                <ActionButton className="mb-1 mx-2" text="Cetak PO" />
              )}
              content={() => componentRef.current}
            />
          )} */}
          <BackButton
            onClick={() =>
              history.push('/transaksi/purchase-order-aset', { ...routerState })
            }
          />
        </div>
      </Card.Header>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
      ) : (
        <>
          <PageContent />
          <div style={{ display: 'none' }}>
            <PrintPurchaseOrder
              ref={componentRef}
              dataPurchaseOrder={dataPurchaseOrder}
              dataPODetail={dataPODetail}
            />
          </div>
        </>
      )}
    </Card>
  );
};

export default DetailPurchaseOrderAset;
