import React from "react";
import { Modal } from "react-bootstrap";
import { InfoSection } from "./InfoSection";
import { TableSection } from "./TableSection";
import { ActionButton, Alert } from "components";
import { useFormikContext } from "formik";
import { ClosePurchaseOrderApi } from "api";
import { formSubmitMapper } from "../Utils";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

export const ModalConfirmSection = ({ show, setShow, setAlertConfig }) => {
  const { values } = useFormikContext();
  const { state } = useLocation();
  const history = useHistory();

  const handleSubmit = () => {
    ClosePurchaseOrderApi.save(formSubmitMapper(values))
      .then(() =>
        history.push("/transaksi/close-purchase-order/purchase-order", {
          ...state,
          createAlert: {
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal ditambah!",
        });
        setShow(false);
      });
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>Konfirmasi Close Purchase Order</Modal.Header>
      <Modal.Body>
        <InfoSection />
        <TableSection />
        <Alert
          className="p-2"
          variant="danger"
          show
          text="Data purchase order ini akan berubah statusnya dari ON PROGRESS menjadi CLOSED dan tidak dapat dikembalikan. Data produksi dengan status CLOSED tidak dapat digunakan sebagai acuan untuk Penerimaan Barang"
        />
        <div className="d-flex justify-content-end">
          <ActionButton
            text="Batal"
            variant="secondary"
            size="sm"
            className="mr-2"
            onClick={() => setShow(false)}
          />
          <ActionButton
            text="CLOSE PURCHASE ORDER"
            variant="danger"
            size="sm"
            type="submit"
            onClick={handleSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
