import Services from '../../services'

class ListJenisVendorApi {
  get(id) {
    return Services.get('/vendor_list_jenis?id_vendor='+ id)
  }

  single(id) {
    return Services.get('/vendor_list_jenis/single?id_vendor_list_jenis='+ id)
  }

  create(value) {
    return Services.post('/vendor_list_jenis', value)
  }

  update(value) {
    return Services.put('/vendor_list_jenis', value)
  }
  
  delete(value) {
    return Services.post('/vendor_list_jenis/delete', value)
  }
}

export default new ListJenisVendorApi()