// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  DataStatus,
  Pagination,
  THead,
  TBody,
  ThFixed,
  Alert,
  TdFixed,
  Tr,
  Th,
  Td,
  ActionButton,
  ReadButton
} from "../../../components";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { DateConvert, TableNumber } from "../../../utilities";
import { IoAdd } from "react-icons/io5"
// API
import { KualifikasiVendorApi } from "../../../api";

const KualifikasiVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  const location = useLocation();
  const paginationFromBrowserState = location?.state?.pagination;

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // Data View
  const [data, setData] = useState([
    {
      jenis: "Supplier, Mandor",
    },
  ]);
  const [page, setPage] = useState(paginationFromBrowserState?.page ?? 1);
  const [totalPage, setTotalPage] = useState(paginationFromBrowserState?.totalPage ?? 1);
  const [dataLength, setDataLength] = useState(paginationFromBrowserState?.dataLength ?? 10);
  const [dataCount, setDataCount] = useState(paginationFromBrowserState?.dataCount ?? 0);

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  let createAlert = location?.state?.variant;

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    KualifikasiVendorApi.getPage(page, dataLength, searchConfig.key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value
        });
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ` + key,
        });
        setShowAlert(key ? true : false);
      }, 750)
    )
  }

  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPage(paginationFromBrowserState.page);
        setDataLength(paginationFromBrowserState.dataLength);
        setTotalPage(paginationFromBrowserState.totalPage);
        setDataCount(paginationFromBrowserState.dataCount);

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle("Kualifikasi Vendor");

    if (createAlert !== undefined) {
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text });
      setShowAlert(true);
    } else {
      return checkPaginationHandler().finally(() => getData());
    }

    // jalankan function request data ke server
    checkPaginationHandler().finally(() => getData());

    // menangani pembersihan saat fetching data
    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  useEffect(() => {
    getData();
  }, [page, dataLength, searchConfig.key]);

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Prakualifikasi Vendor</ThFixed>
              <ThFixed>Kode Vendor</ThFixed>
              <Th>Nama Vendor</Th>
              <Th width={150}>Jenis Vendor</Th>
              <Th width={130}>Status Kualifikasi</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{TableNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="text-left">{DateConvert(new Date(val.tgl_vendor_prakualifikasi)).defaultDMY ?? "-"}</div>
                  <div className="text-left">{val.no_vendor_prakualifikasi ?? "-"}</div>
                </TdFixed>
                <TdFixed>{val.kode_vendor ?? "-"}</TdFixed>
                <Td>{val.nama_vendor ?? "-"}</Td>
                <Td>{val.jenis_vendor ?? "-"}</Td>
                <Td>
                    {val.status_kualifikasi == "0" 
                    ? <div className="text-center">PENDING</div>
                    : (
                      <>
                        {val.status_kualifikasi == "1" ? <div className="text-success text-center">LOLOS</div> : <div className="text-danger text-center">TIDAK LOLOS</div>}
                        <div className="text-center">{val.tgl_kualifikasi != null && val.tgl_kualifikasi.split(" ")[0]}</div>
                      </>
                    )}
                </Td>
                <TdFixed>
                  <div className="text-center">
                    {val.status_kualifikasi == "0" ? (
                      <ActionButton
                        size="sm"
                        variant="success"
                        onClick={() => history.push("/master/kualifikasi-vendor/kualifikasi/" + val.id_vendor_prakualifikasi, {
                          pagination: {
                            page: page,
                            totalPage: totalPage,
                            dataCount: dataCount,
                            dataLength: dataLength,
                          }})
                        }
                      >
                        <IoAdd />
                      </ActionButton>
                    ) : (
                      <ReadButton
                        color="primary"
                        onClick={() => history.push("/master/kualifikasi-vendor/detail/" + val.id_vendor_prakualifikasi, {
                          pagination: {
                            page: page,
                            totalPage: totalPage,
                            dataCount: dataCount,
                            dataLength: dataLength,
                          }})
                        }
                      />
                    )}
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={onInputSearchChange}
                // onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }
    </CRUDLayout>
  );
};

export default KualifikasiVendor;
