import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, ButtonGroup } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
} from "../../../../components";
import {
  TableNumber,
  DateConvert,
  RupiahConvert,
  DecimalConvert,
} from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { IoAddOutline } from "react-icons/io5";
import ModalFilter from "./ModalFilter";
import Axios from "axios";

const VendorTerseleksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationFromBrowserState = location?.state?.pagination;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataVendorTerseleksi, setDataVendorTerseleksi] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: paginationFromBrowserState?.page ?? 1,
    dataLength: paginationFromBrowserState?.dataLength ?? 10,
    totalPage: paginationFromBrowserState?.totalPage ?? 1,
    dataCount: paginationFromBrowserState?.dataCount ?? 0,
    key: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [dataFilter, setDataFilter] = useState({
    active: paginationFromBrowserState?.active,
    tgl_seleksi_vendor: paginationFromBrowserState?.tgl_seleksi_vendor,
    id_item: paginationFromBrowserState?.id_item,
    id_vendor: paginationFromBrowserState?.id_vendor,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [dropdownFilter, setDropdownFilter] = useState({
    vendor: [],
    itemBuaso: [],
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getVendor({
      q: searchConfig?.key,
      page: paginationConfig?.page,
      per_page: paginationConfig?.dataLength,
      tgl_seleksi_vendor: dataFilter?.tgl_seleksi_vendor,
      id_item: dataFilter?.id_item,
      id_vendor: dataFilter?.id_vendor,
    })
      .then(({ data }) => {
        setDataVendorTerseleksi(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const getDropdown = () => {
    Axios.all([
      PurchaseOrderApi.getDropdownVendor({ status_vendor: "1" }),
      PurchaseOrderApi.getDropdownItemBuaso(),
    ]).then(
      Axios.spread((dataVendor, buaso) => {
        const mappingVendor = (data) =>
          data.map((val) => ({
            label: val?.nama_vendor,
            value: val?.id_vendor,
          }));
        const mappingBuaso = (data) =>
          data.map((val) => ({
            label: val?.nama_item,
            value: val?.id_item_buaso,
          }));
        setDropdownFilter({
          vendor: mappingVendor(dataVendor?.data?.data ?? []),
          itemBuaso: mappingBuaso(buaso?.data?.data ?? []),
        });
      })
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };
  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPaginationConfig({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  useEffect(() => {
    setNavbarTitle("LIST SELEKSI VENDOR AVAILABLE");

    checkPaginationHandler().finally(() => getInitialData());
    checkAlert();
    getDropdown();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    dataFilter?.tgl_seleksi_vendor,
    dataFilter?.id_item,
    dataFilter?.id_vendor,
  ]);

  useEffect(() => {
    getInitialData();
  }, [searchConfig?.key, paginationConfig?.page, paginationConfig?.dataLength]);

  const PageContent = () => {
    const groupedData = dataVendorTerseleksi.reduce((acc, curr) => {
      if (acc[curr.id_vendor]) {
        acc[curr.id_vendor].count++;
        acc[curr.id_vendor].data.push(curr);
      } else {
        acc[curr.id_vendor] = { count: 1, data: [curr] };
      }
      return acc;
    }, {});

    const tableData = Object.values(groupedData).flatMap(
      ({ count, data }, ind) =>
        data.map((item, index) => ({
          ...item,
          rowspan: index === 0 ? count : 0,
          no: ind + 1,
        }))
    );

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Vendor</Th>
            <Th>Informasi Seleksi Vendor</Th>
            <Th>Item Purchase Request</Th>
            <ThFixed>Qty. Order</ThFixed>
            <Th>Harga Kesepakatan</Th>
            <Th>Total Harga</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {tableData.map((val, index) => {
            const total =
              parseFloat(val.harga_kesepakatan) * parseFloat(val.qty_order);
            return (
              <Tr key={index}>
                {val.rowspan !== 0 && (
                  <TdFixed rowSpan={val.rowspan}>{val.no}</TdFixed>
                )}
                {val.rowspan !== 0 && (
                  <Td rowSpan={val.rowspan}>
                    <div className="py-1" style={{ width: "200px" }}>
                      {val.nama_vendor}
                    </div>
                  </Td>
                )}
                <Td>
                  <div>
                    {DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY}
                  </div>
                  <div>{val.no_seleksi_vendor}</div>
                </Td>
                <Td>{val.nama_item ?? "-"}</Td>
                <Td className="text-nowrap">
                  {DecimalConvert(parseFloat(val.qty_order ?? 0)).getWithComa +
                    " " +
                    val?.nama_satuan}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      parseFloat(val.harga_kesepakatan ?? 0).toString()
                    ).getWithComa
                  }
                </Td>

                <Td className="text-right">
                  {RupiahConvert(parseFloat(total ?? 0).toString()).getWithComa}
                </Td>
                {val.rowspan !== 0 && (
                  <Td rowSpan={val.rowspan}>
                    <ButtonGroup size="sm">
                      <ActionButton
                        size="sm"
                        className="mx-1 text-nowrap"
                        text={<IoAddOutline />}
                        onClick={() =>
                          history.push(
                            "/transaksi/purchase-order/tambah/" +
                              val.id_seleksi_vendor,
                            {
                              data: val.id_vendor,
                              pagination: paginationConfig,
                            }
                          )
                        }
                      />
                    </ButtonGroup>
                  </Td>
                )}
              </Tr>
            );
          })}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value;
                setSearchConfig({
                  ...searchConfig,
                  key: e.target.value,
                });
                setAlertConfig({
                  show: key ? true : false,
                  variant: "primary",
                  text: "Hasil dari pencarian: " + key,
                });
              }}
            />
            <FilterButton
              active={dataFilter?.active}
              onClick={() => {
                setModalFilter(true);
              }}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => history.push("/transaksi/purchase-order")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>
      {console.log(dataFilter)}
      {console.log(modalFilter)}
      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataVendorTerseleksi ? (
        dataVendorTerseleksi.length > 0 ? (
          <>
            <PageContent />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      {modalFilter && (
        <ModalFilter
          toggle={() => setModalFilter(false)}
          data={dataFilter}
          setData={setDataFilter}
          dropdown={dropdownFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default VendorTerseleksi;
