import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../../components";
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
} from "../../../../../utilities";
import Logo from "../../../../../assets/image/LogoAJB.jpg";
import ttd_direktur from "../../../../../assets/image/ttd_direktur.png";
import ttd_petugas from "../../../../../assets/image/ttd_ketut_sadiana.png";
const PrintContent = ({ dataPurchaseOrder, dataPODetail }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td>
        <p>{title}</p>
      </td>
      <td>
        <p className="pl-4 pr-2">:</p>
      </td>
      <td>
        <p>{value}</p>
      </td>
    </tr>
  );

  const InfoItems = ({ title, value }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  let total = 0;
  let nilaiDiskon = 0;
  let setelahDiskon = 0;
  let nilaiPPN = 0;
  let setelahPPN = 0;
  let nilaiDP = 0;
  let totalBiayaAngkut = 0;
  let grandTotal = 0;

  dataPODetail.map((val, index) => {
    const jumlah = parseFloat(val.qty_order * val.harga_kesepakatan);
    const jumlahBiayaAngkut =
      parseFloat(val.qty_order ?? 0) *
      parseFloat(val.harga_kesepakatan_biaya_angkut ?? 0);

    totalBiayaAngkut = totalBiayaAngkut + jumlahBiayaAngkut;
    total = total + jumlah;
    nilaiDiskon = dataPurchaseOrder?.diskon
      ? parseFloat(dataPurchaseOrder?.diskon) === 0
        ? 0
        : parseFloat((dataPurchaseOrder?.diskon / 100) * total)
      : 0;
    setelahDiskon = parseFloat(total - nilaiDiskon);
    nilaiPPN = dataPurchaseOrder?.ppn
      ? parseFloat(dataPurchaseOrder?.ppn) === 0
        ? 0
        : parseFloat((dataPurchaseOrder?.ppn / 100) * setelahDiskon)
      : 0;
    setelahPPN = parseFloat(setelahDiskon + nilaiPPN);

    grandTotal = setelahPPN + totalBiayaAngkut;
    nilaiDP = dataPurchaseOrder?.dp
      ? parseFloat(dataPurchaseOrder?.dp) === 0
        ? 0
        : parseFloat((dataPurchaseOrder?.dp / 100) * grandTotal)
      : 0;
  });

  const statusApproval = dataPurchaseOrder?.stakeholder?.pop();

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  const CatatanSection = () => (
    <div>
      <div>Pesanan ini selambat-lambatnya telah sampai pada :</div>
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItems
                title="Tanggal"
                value={
                  dataPurchaseOrder.tgl_pengiriman
                    ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman))
                        .detail
                    : "-"
                }
              />
              <InfoItems
                title="Pambayaran"
                value={dataPurchaseOrder.pembayaran}
              />
              <InfoItems
                title="Dikirim ke"
                value={dataPurchaseOrder.alamat_tujuan_pengiriman}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <table>
              <tbody>
                <InfoItems
                  title="Sub Total"
                  value={
                    RupiahConvert(parseFloat(total).toString()).getWithComa
                  }
                />
                <InfoItems
                  title={`Diskon ${
                    DecimalConvert(dataPurchaseOrder.diskon ?? 0).getWithComa
                  } %`}
                  value={RupiahConvert(nilaiDiskon.toString()).getWithComa}
                />
                <InfoItems
                  title="Harga Setelah DIskon"
                  value={
                    RupiahConvert(parseFloat(setelahDiskon).toString())
                      .getWithComa
                  }
                />
                <InfoItems
                  title={`PPN ${
                    DecimalConvert(dataPurchaseOrder.ppn ?? 0).getWithComa
                  } %`}
                  value={RupiahConvert(nilaiPPN.toString()).getWithComa}
                />
                <InfoItems
                  title="Harga Setelah Pajak"
                  value={
                    RupiahConvert(parseFloat(setelahPPN).toString()).getWithComa
                  }
                />
                <InfoItems
                  title="Biaya Angkut"
                  value={
                    RupiahConvert(parseFloat(totalBiayaAngkut).toString())
                      .getWithComa
                  }
                />
                <InfoItems
                  title="Grand Total"
                  value={
                    RupiahConvert(parseFloat(grandTotal).toString()).getWithComa
                  }
                />
                <InfoItems
                  title={`DP ${
                    DecimalConvert(dataPurchaseOrder.dp ?? 0).getWithComa
                  } %`}
                  value={
                    RupiahConvert(parseFloat(nilaiDP).toString()).getWithComa
                  }
                />
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <div>
        <img src={Logo} width={110} />
      </div>
      <div style={{ marginLeft: 30 }}>
        <p>
          <br />
          <b>PT. Adi Jaya Beton</b>
          <br />
          Jln. By Pass Ngurah Rai Suwung (Seberang Lotte Mart) – Denpasar - Bali
          <br />
          No. Telepon: 081237102424
        </p>
      </div>
    </div>
  );

  const InfoSection = () => {
    return (
      <>
        <KopSuratSection />
        <Row className="mt-3">
          <Col>
            <table>
              <tbody>
                <p>Deskripsi</p>
                <InfoItem
                  title="No. PO"
                  value={
                    dataPurchaseOrder.no_purchase_order
                      ? dataPurchaseOrder.no_purchase_order
                      : "-"
                  }
                />
                <InfoItem
                  title="Tanggal"
                  value={
                    dataPurchaseOrder.tgl_purchase_order
                      ? DateConvert(
                          new Date(dataPurchaseOrder.tgl_purchase_order)
                        ).detail
                      : "-"
                  }
                />
                <InfoItem
                  title="Referensi Purchase Request"
                  value={
                    dataPurchaseOrder.no_purchase_request
                      ? dataPurchaseOrder.no_purchase_request
                      : "-"
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table>
              <tbody>
                <InfoItem title="Kepada" />
                <span>
                  {dataPurchaseOrder.nama_vendor
                    ? dataPurchaseOrder.nama_vendor
                    : "-"}
                </span>
                <br />
                <span>
                  {dataPurchaseOrder.alamat_vendor
                    ? dataPurchaseOrder.alamat_vendor
                    : "-"}
                </span>
                <br />
                <span>
                  {dataPurchaseOrder.telepon ? dataPurchaseOrder.telepon : "-"}
                </span>
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    );
  };

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src }) => (
      <Col className="text-center">
        <div>{title}</div>
        {/* <div style={{ height: 80 }}></div> */}
        <div>
          <img src={src} height={80} width={130} />
        </div>
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    );

    return (
      <Row className="mt-5 pt-3">
        <TTDItems
          title="Disahkan,"
          value="I Dewa Gede Tagel"
          src={ttd_direktur}
          jabatan="Direktur"
        />
        <TTDItems
          title="Dibuat Oleh"
          value="I Ketut Sadiana"
          src={ttd_petugas}
          jabatan="Manager SDM, Umum, Procurement"
        />
      </Row>
    );
  };

  const ListItemPurchaseOrderSection = () => {
    return (
      <>
        <div className="py-2 pl-1">
          <span>Kami tempatkan order pembelian dengan sbb :</span>
        </div>
        {dataPODetail ? (
          dataPODetail.length > 0 ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode</ThFixed>
                    <Th>Item Barang</Th>
                    <Th>Proyek</Th>
                    <Th>Satuan Beli</Th>
                    <Th>Qty. Order</Th>
                    <Th>Harga Kesepakatan</Th>
                    <Th>Vendor Angkut</Th>
                    <Th>Harga Kesepakatan Biaya Angkut</Th>
                    <Th>Jumlah</Th>
                  </Tr>
                </THead>
                <TBody>
                  {dataPODetail.map((val, index) => {
                    const jumlah =
                      parseFloat(val.qty_order * val.harga_kesepakatan) +
                      parseFloat(
                        val.qty_order *
                          parseFloat(val.harga_kesepakatan_biaya_angkut ?? 0)
                      );

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>
                          <div style={{ width: "200px" }}>
                            {/* {val.dimensi === "3"
                                ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                                : val.dimensi === "2"
                                ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                : val.dimensi === "1"
                                ? `${val.nama_item} (${val.pj})`
                                : val.dimensi === "0"
                                ? val.nama_item
                                : ""} */}
                            {val.nama_item}
                          </div>
                        </Td>
                        <Td>{val.nama_proyek ?? "-"}</Td>
                        <Td>{val.nama_satuan ? val.nama_satuan : "-"}</Td>
                        <Td className="text-right">
                          {val.qty_order
                            ? decimalConvert(parseFloat(val.qty_order))
                            : "-"}
                        </Td>

                        <Td className="text-right">
                          {
                            RupiahConvert(
                              parseFloat(val.harga_kesepakatan ?? 0).toString()
                            ).getWithComa
                          }
                        </Td>
                        <Td>{val?.nama_vendor_angkut ?? "-"}</Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(
                              parseFloat(
                                val.harga_kesepakatan_biaya_angkut ?? 0
                              ).toString()
                            ).getWithComa
                          }
                        </Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(parseFloat(jumlah).toString())
                              .getWithComa
                          }
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
              <CatatanSection />
              <div>
                <span>Keterangan :</span>
                <p>{dataPurchaseOrder.keterangan}</p>
              </div>
              {statusApproval.status_approval === "APP" ? <TTDSection /> : ""}
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  };

  return (
    <div className="p-4">
      <InfoSection />
      <ListItemPurchaseOrderSection />
    </div>
  );
};

export default class PrintPurchaseOrder extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PrintContent
        dataPurchaseOrder={this.props.dataPurchaseOrder}
        dataPODetail={this.props.dataPODetail}
      />
    );
  }
}
