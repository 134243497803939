// React
import { useEffect, useState } from "react";

// API
import axios from "axios";
import { TransferUpahJobMixApi } from "api";

// Components
import { ActionButton, Alert, DataStatus } from "components";
import { Form, Modal } from "react-bootstrap";
import { DateConvert } from "utilities";

// Form
import { Formik } from "formik";
import * as yup from "yup";

// View Components
import { FormSection, InfoSection } from "../sections";

// View Functions
import { mappingData } from "../../functions";
import { useHistory, useLocation } from "react-router-dom";

const TransferModal = ({
  data,
  toggle,
  isCreate,
  title,
  toggleSuccess,
  getLastQtyTransfer,
}) => {
  const location = useLocation();
  const history = useHistory();
  // States
  const [modal, setModal] = useState({
    loading: false,
    status: true,
  });
  const [dataForm, setDataForm] = useState({});
  const [dataDropdown, setDataDropdown] = useState({
    vendor: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  useEffect(() => {
    if (isCreate) {
      // Variables
      const tanggal = DateConvert(new Date()).default;

      setModal({
        loading: true,
        status: false,
      });

      axios
        .all([
          TransferUpahJobMixApi.no_baru(tanggal),
          TransferUpahJobMixApi.getDropdownVendor(),
        ])
        .then(
          axios.spread((no, vendor) => {
            setDataForm({
              no: no.data.data,
              tanggal,
            });
            setDataDropdown({
              vendor: mappingData(vendor, "vendor"),
            });

            setModal({
              loading: false,
              status: true,
            });
          })
        )
        .catch(() => {
          setModal({
            loading: false,
            status: false,
          });
        });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Modal show={true} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">
            {isCreate ? "Tambah" : "Detail"} Data {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      {modal.loading || !modal.status ? (
        <Modal.Body>
          <DataStatus
            loading={modal.loading}
            text={
              modal.loading
                ? "Memuat data..."
                : "Data gagal dimuat, tidak dapat menampilkan data"
            }
          />
        </Modal.Body>
      ) : (
        <Formik
          initialValues={{
            ...data,
            tgl_transfer_produksi_upah: isCreate
              ? dataForm.tanggal
              : data.tgl_transfer_produksi_upah,
            no_transfer_produksi_upah: isCreate
              ? dataForm.no
              : data.no_transfer_produksi_upah,
            qty_telah_ditransfer: isCreate
              ? data.qty_transfer
              : data.qty_telah_ditransfer,
            qty_transfer: isCreate ? "" : data.qty_transfer,
            keterangan_transfer: isCreate ? "" : data.keterangan_transfer,
            id_vendor: "",
            harga_satuan: isCreate ? "" : data.harga_satuan,
            nama_proyek: data.nama_proyek,
          }}
          validationSchema={yup.object().shape({
            tgl_permintaan_produksi_upah: yup
              .date()
              .typeError("Masukkan Tanggal")
              .required("Masukkan Tanggal"),
            qty_transfer: yup
              .number()
              .max(
                parseFloat(data.qty_permintaan_produksi ?? 0) -
                  parseFloat(data.qty_transfer ?? 0),
                `Qty. Transfer wajib diisi maksimal ${
                  parseFloat(data.qty_permintaan_produksi ?? 0) -
                  parseFloat(data.qty_transfer ?? 0)
                }`
              )
              .required("Qty. Transfer wajib diisi"),
            id_vendor: yup.string().required("Pilih Vendor"),
            harga_satuan: yup.string().required("Harga Satuan wajib diisi"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (isCreate) {
              TransferUpahJobMixApi.save(values)
                .then(() => {
                  const totalQtyTransfer =
                    parseFloat(getLastQtyTransfer.qty_transfer) +
                    parseFloat(values.qty_transfer);

                  if (
                    totalQtyTransfer >=
                    getLastQtyTransfer?.qty_permintaan_produksi
                  ) {
                    history.push(
                      "/transaksi/transfer-upah-job-mix/list-permintaan-upah",
                      location.state
                    );
                  } else {
                    toggleSuccess();
                    toggle();
                  }
                })
                .catch(() => {
                  setAlertConfig({
                    show: true,
                    text: "Tambah data gagal",
                    variant: "danger",
                  });
                })
                .finally(() => setSubmitting(false));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Alert
                  show={alertConfig.show}
                  variant={alertConfig.variant}
                  text={alertConfig.text}
                  showCloseButton={true}
                  onClose={() => {
                    setAlertConfig({
                      ...alertConfig,
                      show: false,
                    });
                  }}
                />

                <InfoSection />
                <hr />
                <FormSection
                  buaso="upah"
                  readOnly={!isCreate}
                  dropdown={dataDropdown}
                />
              </Modal.Body>

              {isCreate && (
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    text="Simpan"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default TransferModal;
