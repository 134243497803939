import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
} from '../../../../components';
import {
  TableNumber,
  DateConvert,
  RupiahConvert,
  DecimalConvert,
} from '../../../../utilities';
import { PurchaseOrderAsetApi } from '../../../../api';
import { IoAddOutline } from 'react-icons/io5';
import ModalFilter from './ModalFilter';
import Axios from 'axios';
import Thead from 'components/Table/THead';
import { ButtonFilter, InputSearch } from '@bhawanadevteam/react-core';

const VendorTerseleksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationFromBrowserState = location?.state?.pagination;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataVendorTerseleksi, setDataVendorTerseleksi] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: paginationFromBrowserState?.page ?? 1,
    dataLength: paginationFromBrowserState?.dataLength ?? 10,
    totalPage: paginationFromBrowserState?.totalPage ?? 1,
    dataCount: paginationFromBrowserState?.dataCount ?? 0,
    key: '',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [dataFilter, setDataFilter] = useState({
    active: paginationFromBrowserState?.active,
    tgl_seleksi_vendor_aset:
      paginationFromBrowserState?.tgl_seleksi_vendor_aset,
    id_item: paginationFromBrowserState?.id_item,
    id_vendor: paginationFromBrowserState?.id_vendor,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [dropdownFilter, setDropdownFilter] = useState({
    vendor: [],
    itemBuaso: [],
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderAsetApi.getSeleksiVendor({
      q: searchConfig?.key,
      page: paginationConfig?.page,
      per_page: paginationConfig?.dataLength,
      tgl_seleksi_vendor_aset: dataFilter?.tgl_seleksi_vendor_aset,
      id_item: dataFilter?.id_item,
      id_vendor: dataFilter?.id_vendor,
    })
      .then(({ data }) => {
        setDataVendorTerseleksi(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const getDropdown = () => {
    Axios.all([
      PurchaseOrderAsetApi.getDropdownVendor({ status_vendor: '1' }),
      PurchaseOrderAsetApi.getDropdownItemBuaso(),
    ]).then(
      Axios.spread((dataVendor, buaso) => {
        const mappingVendor = (data) =>
          data.map((val) => ({
            label: val?.nama_vendor,
            value: val?.id_vendor,
          }));
        const mappingBuaso = (data) =>
          data.map((val) => ({
            label: val?.nama_item,
            value: val?.id_item_buaso,
          }));
        setDropdownFilter({
          vendor: mappingVendor(dataVendor?.data?.data ?? []),
          itemBuaso: mappingBuaso(buaso?.data?.data ?? []),
        });
      })
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };
  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPaginationConfig({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  useEffect(() => {
    setNavbarTitle('LIST SELEKSI VENDOR ASET AVAILABLE');

    checkPaginationHandler().finally(() => getInitialData());
    checkAlert();
    getDropdown();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    dataFilter?.tgl_seleksi_vendor_aset,
    dataFilter?.id_item,
    dataFilter?.id_vendor,
  ]);

  useEffect(() => {
    getInitialData();
  }, [searchConfig?.key, paginationConfig?.page, paginationConfig?.dataLength]);

  const PageContent = () => {
    const calculateRowspan = (item) => item.list_seleksi_vendor.length;

    const renderVendorRows = (item, i) =>
      item.list_seleksi_vendor.map((val, index) => (
        <Tr key={val.id_seleksi_vendor_aset}>
          {index === 0 && (
            <>
              <TdFixed rowSpan={calculateRowspan(item)}>{i + 1}</TdFixed>
              <Td rowSpan={calculateRowspan(item)}>{item.nama_vendor}</Td>
            </>
          )}
          <Td>
            <div>
              {val.tgl_seleksi_vendor_aset
                ? DateConvert(new Date(val.tgl_seleksi_vendor_aset)).defaultDMY
                : '-'}
            </div>
            <div>{val.no_seleksi_vendor_aset ?? '-'}</div>
          </Td>
          <Td>{val?.nama_aset ?? '-'}</Td>
          <Td className="text-capitalize">{val?.keperluan ?? '-'}</Td>
          <Td className="text-right">
            {
              RupiahConvert(parseFloat(val?.harga_kesepakatan ?? 0).toString())
                .getWithComa
            }
          </Td>
          {index === 0 && (
            <Td rowSpan={calculateRowspan(item)}>
              <ButtonGroup size="sm">
                <ActionButton
                  size="sm"
                  className="mx-1 text-nowrap"
                  text={<IoAddOutline />}
                  onClick={() =>
                    history.push(
                      '/transaksi/purchase-order-aset/tambah/' +
                        val.id_seleksi_vendor_aset,
                      {
                        data: val.id_vendor,
                        pagination: paginationConfig,
                      }
                    )
                  }
                />
              </ButtonGroup>
            </Td>
          )}
        </Tr>
      ));

    const DataTable = () => (
      <Table border="1">
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Vendor</Th>
            <ThFixed>Informasi Seleksi Vendor</ThFixed>
            <Th>Item Aset Yang Diminta</Th>
            <Th>Keperluan</Th>
            <Th>Harga Kesepakatan</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </Thead>
        <TBody>{dataVendorTerseleksi.map(renderVendorRows)}</TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value;
                setSearchConfig({
                  ...searchConfig,
                  key: e.target.value,
                });
                setAlertConfig({
                  show: key ? true : false,
                  variant: 'primary',
                  text: 'Hasil dari pencarian: ' + key,
                });
              }}
            />

            <ButtonFilter
              size="sm"
              className="text-nowrap ml-2"
              active={dataFilter?.active}
              onClick={() => setModalFilter(!modalFilter)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => history.push('/transaksi/purchase-order-aset')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>
      {console.log(dataFilter)}
      {console.log(modalFilter)}
      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataVendorTerseleksi ? (
        dataVendorTerseleksi.length > 0 ? (
          <>
            <PageContent />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      {modalFilter && (
        <ModalFilter
          toggle={() => setModalFilter(false)}
          data={dataFilter}
          setData={setDataFilter}
          dropdown={dropdownFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default VendorTerseleksi;
