import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ButtonGroup } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  InputSearch,
  FilterButton,
  CreateButton,
  ThFixed,
  Table,
  Pagination,
  DataStatus,
  ReadButton,
  Td,
  Th,
} from 'components';
import { DateConvert, DecimalConvert, TableNumber } from 'utilities';
import { ClosePurchaseOrderAsetApi } from 'api';
import { ModalFilter } from './section';
import { saveAs } from 'file-saver';

const ClosePurchaseOrderAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const createAlert = location?.state?.alert?.variant;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSalesOrder, setDataSalesOrder] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.closeSalesOrder?.filter?.active,
      tgl_purchase_order_aset_mulai:
        location?.state?.closeSalesOrder?.filter?.tgl_purchase_order_aset_mulai,
      tgl_purchase_order_aset_selesai:
        location?.state?.closeSalesOrder?.filter
          ?.tgl_purchase_order_aset_selesai,
      tgl_close_purchase_order_aset_mulai:
        location?.state?.closeSalesOrder?.filter
          ?.tgl_close_purchase_order_aset_mulai,
      tgl_close_purchase_order_aset_selesai:
        location?.state?.closeSalesOrder?.filter
          ?.tgl_close_purchase_order_aset_selesai,
      id_vendor: location?.state?.closeSalesOrder?.filter?.id_vendor,
    },
    pagination: {
      page: location?.state?.closeSalesOrder?.filter?.page ?? '1',
      dataLength: location?.state?.closeSalesOrder?.filter?.dataLength ?? '10',
      totalPage: location?.state?.closeSalesOrder?.filter?.totalPage ?? '1',
      dataCount: location?.state?.closeSalesOrder?.filter?.dataCount ?? '0',
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    ClosePurchaseOrderAsetApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_purchase_order_aset_mulai:
        dataFilter?.filter?.tgl_purchase_order_aset_mulai,
      tgl_purchase_order_aset_selesai:
        dataFilter?.filter?.tgl_purchase_order_aset_selesai,
      tgl_close_purchase_order_aset_mulai:
        dataFilter?.filter?.tgl_close_purchase_order_aset_mulai,
      tgl_close_purchase_order_aset_selesai:
        dataFilter?.filter?.tgl_close_purchase_order_aset_selesai,
      id_vendor: dataFilter?.filter?.id_vendor,
    })
      .then(({ data }) => {
        setDataSalesOrder(data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        });
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: '1',
            dataLength: '10',
            totalPage: '1',
            dataCount: '0',
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: 'primary',
          text: 'Hasil dari pencarian: ' + key,
        });
      }, 750)
    );
  };

  //EXPORT Data
  const exportData = (file) => {
    setLoadingExport(true);
    console.log(file);

    ClosePurchaseOrderAsetApi.export({
      tipe: file,
      active: undefined,
      tgl_purchase_order_aset_mulai:
        dataFilter?.filter?.tgl_purchase_order_aset_mulai,
      tgl_purchase_order_aset_selesai:
        dataFilter?.filter?.tgl_purchase_order_aset_selesai,
      tgl_close_purchase_order_aset_mulai:
        dataFilter?.filter?.tgl_close_purchase_order_aset_mulai,
      tgl_close_purchase_order_aset_selesai:
        dataFilter?.filter?.tgl_close_purchase_order_aset_selesai,
    })
      .then((res) => {
        const link = res?.data?.data;
        const parse =
          'https://' +
          link
            .split('/')
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join('/');
        saveAs(parse, 'CloseProduksi');
      })
      .catch(() => {
        alert('Export Data Gagal!!');
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle('CLOSE PURCHASE ORDER ASET');
    if (createAlert !== undefined) {
      // setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.alert?.variant,
        text: location?.state?.alert?.text.alert,
        show: true,
      });

      getInitialData();
      history.replace((createAlert = undefined));
    } else {
      return getInitialData(false);
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_order_aset_mulai,
    dataFilter?.filter?.tgl_purchase_order_aset_selesai,
    dataFilter?.filter?.tgl_close_purchase_order_aset_mulai,
    dataFilter?.filter?.tgl_close_purchase_order_aset_selesai,
    dataFilter?.filter?.id_vendor,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Close Purchase Order Aset</ThFixed>
            <ThFixed>Informasi Purchase Order Aset</ThFixed>
            <Th>Vendor</Th>
            <Th>List Item Aset Diminta</Th>
            <Th>List Item Aset Yang Telah Diterima</Th>
          </tr>
        </thead>
        <tbody>
          {dataSalesOrder.length > 0 &&
            dataSalesOrder.map((val, index) => (
              <tr key={index}>
                <Td textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </Td>
                <Td>
                  <ButtonGroup size="sm">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          '/transaksi/close-purchase-order-aset/detail/' +
                            val.id_purchase_order_aset,
                          {
                            ...location?.state,
                            closeSalesOrder: dataFilter,
                            is_spk: val.is_spk,
                          }
                        )
                      }
                    />
                  </ButtonGroup>
                </Td>

                <Td className="text-nowrap">
                  <div>
                    {val.tgl_close_purchase_order_aset
                      ? DateConvert(new Date(val.tgl_close_purchase_order_aset))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_purchase_order_aset ?? '-'}</div>
                </Td>
                <Td className="text-nowrap">
                  <div>
                    {val.tgl_purchase_order_aset
                      ? DateConvert(new Date(val.tgl_purchase_order_aset))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_purchase_order_aset ?? '-'}</div>
                </Td>
                <Td className="text-nowrap">
                  <div>{val.nama_vendor}</div>
                </Td>
                <Td>
                  <div>
                    {val?.detail?.length > 0 ? (
                      <ul className="pl-3">
                        {val?.detail.map((e, i) => (
                          <li key={i}>
                            {e.nama_aset
                              ? `${e.nama_aset} | ${e.keperluan}`
                              : '-'}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      '-'
                    )}
                  </div>
                </Td>
                <Td>
                  <div>
                    {val?.detail_belum_diterima?.length > 0 ? (
                      <ul className="pl-3">
                        {val?.detail_belum_diterima.map((e, i) => (
                          <li key={i}>
                            {e.nama_aset
                              ? `${e.nama_aset} | ${e.nama_aset} | ${e.keperluan}`
                              : '-'}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      '-'
                    )}
                  </div>
                </Td>
              </tr>
            ))}
        </tbody>
      </Table>
    );

    return (
      <>
        <b>List Data Purchase Order Aset Yang Sudah Close</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push(
                '/transaksi/close-purchase-order-aset/purchase-order-aset',
                {
                  ...location?.state,
                  closeSalesOrder: dataFilter,
                }
              )
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};
export default ClosePurchaseOrderAsetList;
