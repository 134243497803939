import {
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  TextArea,
  TextEditor,
} from "components";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { ClosePurchaseOrderApi } from "api";

export const InfoSection = ({ isSpk, action }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    )
  );

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    ClosePurchaseOrderApi.getNomor({ tgl: tanggal, is_spk: isSpk })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          no_close_purchase_order: no,
          tgl_close_purchase_order: tanggal,
          is_spk: isSpk,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          no_close_purchase_order: "",
          tgl_close_purchase_order: null,
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(values?.lain_lain ?? "<p></p>")
        )
      )
    );
  }, [values]);
  return (
    <>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Purchase Order"
            text={
              values.tgl_purchase_order
                ? DateConvert(new Date(values?.tgl_purchase_order))?.detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Purchase Order"
            text={values?.no_purchase_order ?? "-"}
          />
        </Col>
        <Col>
          <InfoItemHorizontal label="Kepada" />
          {values.nama_vendor ?? "-"}
          <br />
          {values.alamat_tujuan_pengiriman ?? "-"}
          <br />
          {values.contact_person ?? "-"}
        </Col>
        <Col>
          <InfoItemHorizontal label="Keterangan" />
          {values.keterangan ?? "-"}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <DatePicker
            label="Tgl. Close Purchase Order"
            disabled={action === "read"}
            onChange={(date) => {
              getNomorHandler(date);
            }}
            selected={
              values?.tgl_close_purchase_order
                ? new Date(values?.tgl_close_purchase_order)
                : null
            }
            error={
              Boolean(errors?.tgl_close_purchase_order) &&
              touched?.tgl_close_purchase_order
            }
            errorText={
              Boolean(errors?.tgl_close_purchase_order) &&
              touched?.tgl_close_purchase_order &&
              errors?.tgl_close_purchase_order
            }
          />
        </Col>
        <Col>
          <Input
            label="No. Close Purchase Order"
            disabled
            value={
              isNomorLoading ? "Memuat Data.." : values?.no_close_purchase_order
            }
            placeholder="Pilih tanggal terlebih dahulu"
            error={
              Boolean(errors?.no_close_purchase_order) &&
              touched?.no_close_purchase_order
            }
            errorText={
              Boolean(errors?.no_close_purchase_order) &&
              touched?.no_close_purchase_order &&
              errors?.no_close_purchase_order
            }
          />
        </Col>
      </Row>
    </>
  );
};
