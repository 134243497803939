// Components
import { DateConvert, DecimalConvert } from "utilities";

// Form
import { useFormikContext } from "formik";

// View Components
import List from "../List";

const InfoSection = () => {
  // Variables
  const { values } = useFormikContext();
  console.log(values);

  return (
    <table>
      <tbody>
        <List label="Tgl. Permintaan Produksi">
          {values.tgl_permintaan_produksi_upah
            ? DateConvert(new Date(values.tgl_permintaan_produksi_upah)).detail
            : "-"}
        </List>
        <List label="No. Permintaan Produksi">
          {values.no_permintaan_produksi_upah ?? "-"}
        </List>
        <List label="Proyek">{values.nama_proyek ?? "-"}</List>
        <List label="Item Upah">{values.nama_item ?? "-"}</List>
        <List label="Satuan">{values.kode_satuan ?? "-"}</List>
        <List label="Qty. Permintaan Produksi">
          {DecimalConvert(values.qty_permintaan_produksi ?? 0).getWithComa}
        </List>
        <List label="Total Qty. Telah Ditransfer">
          {DecimalConvert(values.qty_telah_ditransfer ?? 0).getWithComa}
        </List>
      </tbody>
    </table>
  );
};

export default InfoSection;
