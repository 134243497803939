import Services from "../../services";

class PurchaseOrderApi {
  get(params) {
    return Services.get("/close_po/already_to_close", { params });
  }

  getPO(params) {
    return Services.get("/close_po/ready_to_close", { params });
  }

  getSingle(params) {
    return Services.get("/close_po/single", { params });
  }

  getVendor(params) {
    return Services.get("/purchase_order/list_vendor", { params });
  }

  getDropdownVendor(params) {
    return Services.get("/dropdown/vendor", { params });
  }
  getDropdownItemBuaso() {
    return Services.get("/dropdown/item_buaso");
  }

  getSingleVendor(params) {
    return Services.get("/purchase_order/list_request_vendor", { params });
  }

  getNomor(params) {
    return Services.get("/close_po/nomor", { params });
  }

  save(data) {
    return Services.put("/close_po", data);
  }
}

export default new PurchaseOrderApi();
