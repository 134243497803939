import Services from "../../../services";

class ItemUpahApi {
  get(data) {
    return Services.get("/vendor_item_subcon?id_vendor=" + data);
  }

  single(id) {
    return Services.get("/vendor_item_subcon/single?id_item_subcon_vendor=" + id);
  }

  getKelompok() {
    return Services.get("/vendor_item_subcon/kelompok_subcon");
  }

  getItem(data) {
    return Services.get("/vendor_item_subcon/item_subcon?id_kelompok=" + data);
  }

  create(value) {
    return Services.post("/vendor_item_subcon", value);
  }

  delete(value) {
    return Services.post("/vendor_item_subcon/delete", value);
  }
  export() {
    return Services.get("/vendor_item_subcon/csv");
  }
}

export default new ItemUpahApi();
