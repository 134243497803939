import Services from "../../services"

class LaporanPurchaseOrderApi {
  getPage(params) {
    return Services.get("/monitoring_purchase_order", { params })
  }

  getExport(params) {
    return Services.get("/purchase_order_report", { params })
  }

  getExportVendor(params) {
    return Services.get("/purchase_order_report/vendor", { params })
  }

  getDropdown(params) {
    return Services.get("/monitoring_purchase_order/dropdown", { params })
  }
}

export default new LaporanPurchaseOrderApi()
