import Services from 'services';

class TransferSubkonProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get('/transfer_subkon_produksi_aset/page', {
      params,
    });
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get('/transfer_subkon_produksi_aset/single', {
      params,
    });
    return fetch.data;
  }

  async dropdownVendor(params) {
    const fetch = await Services.get('/dropdown/vendor', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_vendor,
      value: val.id_vendor,
    }));
  }

  async dropdownItem(params) {
    const fetch = await Services.get('/dropdown/item_subkon_produksi_aset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
    }));
  }
}

export default new TransferSubkonProduksiAsetApi();
