import React, { useState, useEffect } from 'react';
import { Row, Col, Card, ButtonGroup } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
  TextArea,
  SelectSearch,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { PurchaseOrderApi } from 'api';
import { IoAddOutline, IoEyeOutline, IoTrashOutline } from 'react-icons/io5';
import { date } from 'yup/lib/locale';
import { Vendor } from 'pages/Master';
import ReactNumberFormat from 'components/Input/ReactNumberFormat';
import { FiRefreshCcw } from 'react-icons/fi';
import { ModalSeleksiVendor } from '../Section';

const TambahPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_seleksi_vendor } = useParams();
  const location = useLocation();
  const id_vendor = location?.state?.data;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [isFetchingPO, setIsFetchingPO] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState({});
  const [dataListItemPurchaseOrder, setDataListItemPurchaseOrder] = useState(
    []
  );
  const [dropdownVendor, setDropdownVendor] = useState([]);
  const [noBaru, setNoBaru] = useState('');
  const [loadiNoBaru, setLoadiNoBaru] = useState(true);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([PurchaseOrderApi.getDropdownVendor({ status_vendor: '1' })])
      .then(
        Axios.spread((dataVendor) => {
          setDropdownVendor(dataVendor.data.data);
        })
      )
      .catch((err) => Alert('data gagal dimuat'))
      .finally(() => setIsPageLoading(false));
  };

  const getPurchaseOrderSingleVendor = ({ id_vendor }) => {
    setIsFetchingPO(true);
    setDataListItemPurchaseOrder([]);
    PurchaseOrderApi.getSingleVendor({ id_vendor })
      .then(({ data }) => {
        setDataSeleksiVendor({ ...data.data });
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsFetchingPO(false));
  };

  const getNoPurchaseOrder = (date, setFieldValue) => {
    const tgl = date ? date : new Date().toISOString().slice(0, 10);
    Axios.all([PurchaseOrderApi.getNomor({ tanggal: tgl })])
      .then(
        Axios.spread((data) => {
          setNoBaru(data.data.data);
        })
      )
      .catch(() => {
        // setFieldValue("tgl_purchase_order", "");
        // setFieldValue("no_purchase_order", "");
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Nomor gagal diperoleh!',
        });
      });
    // .finally(() => {
    //   loadiNoBaru(false);
    // });
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat('id-ID').format(checkConvert);
  };

  useEffect(() => {
    setNavbarTitle('Purchase Order');
    getInitialData();
    getNoPurchaseOrder();
    getPurchaseOrderSingleVendor({ id_seleksi_vendor, id_vendor });
    return () => {
      setIsPageLoading(false);
    };
  }, []);

  useEffect(() => {
    return () => {};
  }, [dataListItemPurchaseOrder]);

  const formInitialValues = {
    id_seleksi_vendor: id_seleksi_vendor,
    id_vendor: id_vendor,
    tgl_purchase_order: new Date(),
    no_purchase_order: noBaru,
    tgl_pengiriman: new Date(),
    tgl_jatuh_tempo: new Date(),
    alamat_tujuan_pengiriman: dataSeleksiVendor.alamat_tujuan_pengiriman
      ? dataSeleksiVendor.alamat_tujuan_pengiriman
      : 'PT. Adi Jaya Beton (Jl. Bypass Ngurah Rai No.229x, Sesetan, Denpasar Selatan, Kota Denpasar, Bali 80223)',
    keterangan: dataSeleksiVendor.keterangan
      ? dataSeleksiVendor.keterangan
      : '',
    diskon: dataSeleksiVendor.diskon ? dataSeleksiVendor.diskon : 0,
    ppn: dataSeleksiVendor.ppn ? dataSeleksiVendor.ppn : 0,
    dimensi: dataSeleksiVendor.dimensi ? dataSeleksiVendor.dimensi : '0',
    pj: dataSeleksiVendor.pj ? dataSeleksiVendor.pj : '',
    lb: dataSeleksiVendor.lb ? dataSeleksiVendor.lb : '',
    tb: dataSeleksiVendor.tb ? dataSeleksiVendor.tb : '',
    dp: dataSeleksiVendor.dp ? dataSeleksiVendor.dp : 0,
    pembayaran: '',
    detail: [],
  };

  const formValidationSchema = Yup.object().shape({
    id_vendor: Yup.string().required('Pilih vendor'),
    tgl_purchase_order: Yup.string().required('Pilih tanggal purchase order'),
    tgl_pengiriman: Yup.string().required('Pilih tanggal pengiriman'),
    alamat_tujuan_pengiriman: Yup.string().required(
      'Masukan alamat tujuan pengiriman'
    ),
    // diskon: Yup.string().required(),
    // ppn: Yup.string().required(),
    pembayaran: Yup.string().required('Masukan Pembayaran'),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      no_purchase_order: noBaru,
      detail: values.detail.map((val) => ({
        id_item_buaso: val.id_item_buaso,
        qty_order: val.qty_order,
        harga_kesepakatan: val.harga_kesepakatan,
        id_seleksi_vendor_detail: [val.id_seleksi_vendor_detail],
        harga_kesepakatan_biaya_angkut: val.harga_kesepakatan_biaya_angkut,
        id_vendor_angkut: val.id_vendor_angkut,
      })),
    };

    PurchaseOrderApi.createOrUpdate(finalValues)
      .then(() => {
        history.push('/transaksi/purchase-order/vendor-terseleksi', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil ditambah!',
          },
          pagination: location?.state?.pagination,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal ditambah!',
        });
      });
  };

  // COMPONENTS
  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>
          <small>{title}</small>
        </td>
        <td>
          <small className="pl-4 pr-2">:</small>
        </td>
        <td>
          <small>{value}</small>
        </td>
      </tr>
    );

    return (
      <>
        <table>
          <tbody>
            <InfoItem
              title="Kode Vendor"
              value={
                dataSeleksiVendor.kode_vendor
                  ? dataSeleksiVendor.kode_vendor
                  : '-'
              }
            />
            <InfoItem
              title="Vendor"
              value={
                dataSeleksiVendor.nama_vendor
                  ? dataSeleksiVendor.nama_vendor
                  : '-'
              }
            />
            <InfoItem
              title="Contact Person"
              value={
                dataSeleksiVendor.contact_person
                  ? dataSeleksiVendor.contact_person
                  : '-'
              }
            />
            <InfoItem
              title="Alamat Vendor"
              value={
                dataSeleksiVendor.alamat_vendor
                  ? dataSeleksiVendor.alamat_vendor
                  : '-'
              }
            />
          </tbody>
        </table>
        <hr />
      </>
    );
  };

  const ListItemSeleksiVendor = ({
    dataListItemPurchaseOrder,
    setDataListItemPurchaseOrder,
  }) => {
    const { values, setValues } = useFormikContext();
    const [modalDetailSelesiVendorDetail, setModalDetailSelesiVendorDetail] =
      useState({ id: '', show: false });
    return (
      <>
        {modalDetailSelesiVendorDetail?.show && (
          <ModalSeleksiVendor
            setModalConfig={setModalDetailSelesiVendorDetail}
            modalConfig={modalDetailSelesiVendorDetail}
          />
        )}
        <div className="py-2 pl-1">
          <b>List Item Seleksi Vendor</b>
        </div>
        {dataSeleksiVendor?.seleksi_vendor ? (
          dataSeleksiVendor?.seleksi_vendor?.length > 0 ? (
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Kode Item</ThFixed>
                  <Th>Nama Item</Th>
                  <Th>Qty</Th>
                  <Th>Satuan Beli</Th>
                  <Th>No. Purchase Request</Th>
                  <Th>Proyek</Th>
                  <Th>Tgl. Seleksi</Th>
                  <ThFixed>No. Seleksi Vendor</ThFixed>
                  <ThFixed>Aksi</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {dataSeleksiVendor?.seleksi_vendor.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item ? val.kode_item : '-'}</TdFixed>
                    <Td>
                      <div style={{ width: '200px' }}>
                        {/* {val.dimensi === "3"
                          ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                          : val.dimensi === "2"
                          ? `${val.nama_item} (${val.pj} X ${val.lb})`
                          : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                          ? val.nama_item
                          : "-"} */}
                        {val.nama_item}
                      </div>
                    </Td>
                    <Td textRight>
                      {val.qty_order
                        ? decimalConvert(parseFloat(val.qty_order))
                        : '-'}
                    </Td>
                    <Td>{val.nama_satuan ? val.nama_satuan : '-'}</Td>
                    <Td>
                      {val.no_purchase_request ? val.no_purchase_request : '-'}
                    </Td>
                    <Td>{val.nama_proyek ? val.nama_proyek : '-'}</Td>
                    <Td>
                      {val.tgl_seleksi_vendor
                        ? DateConvert(new Date(val.tgl_seleksi_vendor)).detail
                        : '-'}
                    </Td>
                    <Td>{val.no_seleksi_vendor ?? '-'}</Td>
                    <Td>
                      <div className="d-flex justify-content-center">
                        <ActionButton
                          size="sm"
                          className="mr-1"
                          text={<IoEyeOutline />}
                          onClick={() => {
                            setModalDetailSelesiVendorDetail({
                              id: val.id_seleksi_vendor,
                              show: true,
                            });
                          }}
                        />
                        <ActionButton
                          size="sm"
                          text={<IoAddOutline />}
                          onClick={() =>
                            setValues({
                              ...values,
                              detail: [...values?.detail, val],
                            })
                          }
                          disable={values?.detail?.find(
                            (po) =>
                              po.id_seleksi_vendor_detail ===
                              val.id_seleksi_vendor_detail
                          )}
                        />
                      </div>
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  };

  const ListItemPurchaseOrderSection = ({ values, setFieldValue }) => {
    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;
    let nilaiDP = 0;
    let totalBiayaAngkut = 0;
    let grandTotal = 0;
    const { setValues } = useFormikContext();

    const TableFooterItems = ({ text, input, value }) => (
      <Tr>
        <Td colSpan={9} className="text-right">
          <b>{text}</b>
        </Td>
        <Td textRight colSpan={3}>
          <div className="d-flex align-items-center justify-content-between text-nowrap">
            {input ? (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ width: '70px' }}>{input}</div>
                <div className="pb-1 ml-2">
                  <b>%</b>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="text-right">
              <b>{value}</b>
            </div>
          </div>
        </Td>
      </Tr>
    );
    console.log('values', values);

    return (
      <>
        <div className="py-2 pl-1">
          <b>List Item Purchase Order</b>
        </div>
        {values?.detail ? (
          values?.detail.length > 0 ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode Item</ThFixed>
                    <Th>Nama Item</Th>
                    <Th>Proyek</Th>
                    <Th>Satuan Beli</Th>
                    <Th>Qty Order</Th>
                    <Th>Harga Kesepakatan</Th>
                    <Th className="text-nowrap" width="200">
                      Vendor Angkut
                    </Th>
                    <Th>Harga Kesepakatan Biaya Angkut</Th>
                    <Th>Jumlah</Th>
                    <ThFixed>Aksi</ThFixed>
                  </Tr>
                </THead>
                <TBody>
                  {values.detail.map((val, index) => {
                    const jumlah = parseFloat(
                      val.qty_order * val.harga_kesepakatan
                    );
                    const jumlahBiayaAngkut =
                      parseFloat(val.qty_order ?? 0) *
                      parseFloat(val.harga_kesepakatan_biaya_angkut ?? 0);

                    totalBiayaAngkut = totalBiayaAngkut + jumlahBiayaAngkut;
                    total = total + jumlah;
                    nilaiDiskon = values.diskon
                      ? parseFloat(values.diskon) === 0
                        ? 0
                        : parseFloat((values.diskon / 100) * total)
                      : 0;
                    setelahDiskon = parseFloat(total - nilaiDiskon);
                    nilaiPPN = values.ppn
                      ? parseFloat(values.ppn) === 0
                        ? 0
                        : parseFloat((values.ppn / 100) * setelahDiskon)
                      : 0;
                    setelahPPN = parseFloat(setelahDiskon + nilaiPPN);

                    grandTotal = setelahPPN + totalBiayaAngkut;
                    nilaiDP = values.dp
                      ? parseFloat(values.dp) === 0
                        ? 0
                        : parseFloat((values.dp / 100) * grandTotal)
                      : 0;

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>
                          <div style={{ width: '200px' }}>
                            {/* {val.dimensi === "3"
                              ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                              : val.dimensi === "2"
                              ? `${val.nama_item} (${val.pj} X ${val.lb})`
                              : val.dimensi === "1"
                              ? `${val.nama_item} (${val.pj})`
                              : val.dimensi === "0"
                              ? val.nama_item
                              : ""} */}
                            {val.nama_item}
                          </div>
                        </Td>
                        <Td>{val.nama_proyek}</Td>
                        <Td>{val.nama_satuan}</Td>
                        <Td textRight>
                          {decimalConvert(parseFloat(val.qty_order))}
                        </Td>
                        <Td textRight>
                          {
                            RupiahConvert(
                              parseFloat(val.harga_kesepakatan).toString()
                            ).getWithComa
                          }
                        </Td>
                        <Td textRight>
                          <SelectSearch
                            key={val.id_vendor_angkut}
                            name="id_vendor_angkut"
                            onChange={(item) => {
                              const dataVendorAngkut = values?.detail?.map(
                                (res, ind) => ({
                                  ...res,
                                  id_vendor_angkut:
                                    index === ind
                                      ? item.value
                                      : res.id_vendor_angkut,
                                })
                              );
                              setValues({
                                ...values,
                                detail: dataVendorAngkut,
                              });
                            }}
                            option={dropdownVendor.map((val) => {
                              return {
                                value: val.id_vendor,
                                label: val.nama_vendor,
                              };
                            })}
                            defaultValue={{
                              value: val.id_vendor_angkut ?? '',
                              label: val.id_vendor_angkut
                                ? dropdownVendor.find(
                                    (item) =>
                                      item.id_vendor === val.id_vendor_angkut
                                  )?.nama_vendor
                                : 'Pilih Vendor Angkut',
                            }}
                          />
                        </Td>
                        <Td>
                          <ReactNumberFormat
                            value={val?.harga_kesepakatan_biaya_angkut ?? 0}
                            decimalSeparator=","
                            onChange={(e) => {
                              const valueHargaAngkut = values?.detail?.map(
                                (item, ind) => ({
                                  ...item,
                                  harga_kesepakatan_biaya_angkut:
                                    index === ind
                                      ? parseFloat(e ?? 0)
                                      : parseFloat(
                                          item?.harga_kesepakatan_biaya_angkut
                                        ),
                                })
                              );

                              setValues({
                                ...values,
                                detail: valueHargaAngkut,
                              });
                            }}
                          />
                        </Td>
                        <Td>
                          {
                            RupiahConvert(parseFloat(jumlah).toString())
                              .getWithComa
                          }
                        </Td>
                        <Td>
                          <ButtonGroup>
                            <ActionButton
                              size="sm"
                              variant="danger"
                              text={<IoTrashOutline />}
                              onClick={() => {
                                const filterData = values?.detail?.filter(
                                  (data) => data !== val
                                );
                                console.log('test', filterData);
                                setValues({ ...values, detail: filterData });
                              }}
                            />
                            <ActionButton
                              size="sm"
                              text={<FiRefreshCcw />}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  detail: values.detail.map((res, idx) =>
                                    index === idx
                                      ? {
                                          ...res,
                                          id_vendor_angkut: null,
                                          harga_kesepakatan_biaya_angkut: null,
                                        }
                                      : res
                                  ),
                                });
                              }}
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    );
                  })}
                  <TableFooterItems
                    text="Total"
                    value={
                      RupiahConvert(parseFloat(total).toString()).getWithComa
                    }
                  />
                  <Tr>
                    <Td colSpan={9} className="text-right">
                      <div className="d-flex align-items-center justify-content-end text-nowrap">
                        <b className="mr-2">Diskon (%)</b>
                        <div className="d-flex justify-content-center align-items-center">
                          <div style={{ width: '70px' }}>
                            <ReactNumberFormat
                              value={parseFloat(values.diskon ?? 0)}
                              onChange={(e) => setFieldValue('diskon', e)}
                            />
                          </div>
                        </div>
                      </div>
                    </Td>
                    <Td textRight colSpan={3}>
                      <div className="text-right">
                        <b>
                          {RupiahConvert(nilaiDiskon.toString()).getWithComa}
                        </b>
                      </div>
                    </Td>
                  </Tr>
                  <TableFooterItems
                    text="Total Setelah Diskon"
                    value={RupiahConvert(setelahDiskon.toString()).getWithComa}
                  />
                  <Tr>
                    <Td colSpan={9} className="text-right">
                      <div className="d-flex align-items-center justify-content-end text-nowrap">
                        <b className="mr-2">PPN(%)</b>

                        <div className="d-flex justify-content-center align-items-center">
                          <div style={{ width: '70px' }}>
                            <ReactNumberFormat
                              value={parseFloat(values.ppn ?? 0)}
                              onChange={(e) => setFieldValue('ppn', e)}
                            />
                          </div>
                        </div>
                      </div>
                    </Td>
                    <Td textRight colSpan={3}>
                      <div className="text-right">
                        <b>{RupiahConvert(nilaiPPN.toString()).getWithComa}</b>
                      </div>
                    </Td>
                  </Tr>
                  <TableFooterItems
                    text="Total Setelah PPN"
                    value={RupiahConvert(setelahPPN.toString()).getWithComa}
                  />
                  <TableFooterItems
                    text="Total Biaya Angkut"
                    value={
                      RupiahConvert(totalBiayaAngkut.toString()).getWithComa
                    }
                  />
                  <TableFooterItems
                    text="Grand Total"
                    value={RupiahConvert(grandTotal.toString()).getWithComa}
                  />
                  <Tr>
                    <Td colSpan={9} className="text-right">
                      <div className="d-flex align-items-center justify-content-end text-nowrap">
                        <b className="mr-2">DP(%)</b>

                        <div className="d-flex justify-content-center align-items-center">
                          <div style={{ width: '70px' }}>
                            <ReactNumberFormat
                              value={parseFloat(values.dp ?? 0)}
                              onChange={(e) => setFieldValue('dp', e)}
                            />
                          </div>
                        </div>
                      </div>
                    </Td>
                    <Td textRight colSpan={3}>
                      <div className="text-right">
                        <b>{RupiahConvert(nilaiDP.toString()).getWithComa}</b>
                      </div>
                    </Td>
                  </Tr>
                </TBody>
              </Table>
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
        <hr />
      </>
    );
  };
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Tambah Data Purchase Order</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values)}
                <Row>
                  <Col>
                    <DatePicker
                      label="Tgl. Purchase Order"
                      className="mt-1"
                      selected={
                        values.tgl_purchase_order
                          ? new Date(values.tgl_purchase_order)
                          : ''
                      }
                      onChange={(e) => {
                        const value = e.toISOString().slice(0, 10);
                        setFieldValue('tgl_purchase_order', value);
                        getNoPurchaseOrder(value, setFieldValue);
                      }}
                      error={
                        errors.tgl_purchase_order &&
                        touched.tgl_purchase_order &&
                        true
                      }
                      errorText={errors.tgl_purchase_order}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      label="No. Purchase Order"
                      placeholder="Pilih tanggal untuk menentukan nomor"
                      value={noBaru}
                      error={
                        errors.no_purchase_order &&
                        touched.no_purchase_order &&
                        true
                      }
                      errorText={errors.no_purchase_order}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DatePicker
                      label="Tgl. Pengiriman"
                      className="mt-1"
                      selected={
                        values.tgl_pengiriman
                          ? new Date(values.tgl_pengiriman)
                          : ''
                      }
                      onChange={(e) => {
                        const value = e.toISOString().slice(0, 10);
                        setFieldValue('tgl_pengiriman', value);
                      }}
                      error={
                        errors.tgl_pengiriman && touched.tgl_pengiriman && true
                      }
                      errorText={errors.tgl_pengiriman}
                    />
                    {/* <DatePicker
                      label="Tgl. Jatuh Tempo Pembayaran"
                      className="mt-1"
                      selected={values.tgl_jatuh_tempo ? new Date(values.tgl_jatuh_tempo) : ""}
                      onChange={(e) => {
                        const value = e.toISOString().slice(0, 10);
                        setFieldValue("tgl_jatuh_tempo", value);
                      }}
                      error={errors.tgl_jatuh_tempo && touched.tgl_jatuh_tempo && true}
                      errorText={errors.tgl_jatuh_tempo}
                    /> */}
                    <SelectSearch
                      label="Vendor"
                      name="id_vendor"
                      placeholder="Pilih Vendor"
                      onChange={(val) => {
                        setFieldValue('id_vendor', val.value);
                      }}
                      option={dropdownVendor?.map((val) => {
                        return {
                          value: val.id_vendor,
                          label: val.nama_vendor,
                        };
                      })}
                      defaultValue={{
                        value: values?.id_vendor ?? '',
                        label:
                          values?.id_vendor === ''
                            ? 'Pilih Satuan'
                            : dropdownVendor?.find(
                                (val) => val.id_vendor === values.id_vendor
                              )?.nama_vendor ?? 'Pilih Satuan',
                      }}
                      isDisabled={true}
                      error={errors.id_vendor && touched.id_vendor}
                      errorText={
                        errors.id_vendor &&
                        touched.id_vendor &&
                        errors.id_vendor
                      }
                    />
                    <TextArea
                      label="Alamat Tujuan Pengiriman"
                      name="alamat_tujuan_pengiriman"
                      placeholder="Masukan alamat tujuan pengiriman"
                      value={values.alamat_tujuan_pengiriman}
                      onChange={handleChange}
                      error={
                        errors.alamat_tujuan_pengiriman &&
                        touched.alamat_tujuan_pengiriman &&
                        true
                      }
                      errorText={errors.alamat_tujuan_pengiriman}
                    />
                  </Col>
                  <Col>
                    <TextArea
                      label="Keterangan"
                      name="keterangan"
                      placeholder="Masukan keterangan"
                      value={values.keterangan}
                      onChange={handleChange}
                      error={errors.keterangan && touched.keterangan && true}
                      errorText={errors.keterangan}
                      rows={11}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextArea
                      label="Pembayaran"
                      name="pembayaran"
                      placeholder="Masukan Jangka Waktu Pembayaran"
                      value={values.pembayaran}
                      onChange={handleChange}
                      error={errors.pembayaran && touched.pembayaran && true}
                      errorText={errors.pembayaran}
                    />
                  </Col>
                </Row>
                <hr />
                {isFetchingPO ? (
                  <DataStatus loading={isFetchingPO} text="Memuat data ..." />
                ) : (
                  <>
                    <InfoSection />
                    <ListItemSeleksiVendor
                      dataListItemPurchaseOrder={dataListItemPurchaseOrder}
                      setDataListItemPurchaseOrder={
                        setDataListItemPurchaseOrder
                      }
                    />
                    <ListItemPurchaseOrderSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      dataListItemPurchaseOrder={dataListItemPurchaseOrder}
                      setDataListItemPurchaseOrder={
                        setDataListItemPurchaseOrder
                      }
                    />
                    {values?.detail && values?.detail?.length > 0 && (
                      <div className="d-flex justify-content-end my-3">
                        <ActionButton
                          type="submit"
                          text="Simpan Data Purchase Order"
                          loading={isSubmitting}
                        />
                      </div>
                    )}
                  </>
                )}
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahPurchaseOrder;
