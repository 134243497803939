import React, {
  memo,
  useState,
  useEffect
} from 'react'
import {
  Col,
  Row,
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {
  useQuery
} from 'react-query'
import {
  range,
} from 'lodash'
import {
  Formik,
} from 'formik'
import {
  Select,
  Button,
  ChartDoughnut,
  ChartBarVertical,
  ChartLine,
  DataStatus
} from 'components2'
import {
  Select as SelectDefault
} from 'components'
import {
  dateConvert, 
  numberConvert, 
  rupiahConvert
} from 'utilities2'
import {
  TableNumber
} from 'utilities'
import {
  Table,
  Td
} from 'components'
import DashboardApi from './__DashboardApi__'

const date = dateConvert()
const rupiah = rupiahConvert()
const number = numberConvert()
const yearNow = date.getYear(new Date())

const monthOptions = [
  {label: 'Januari', value: '01'},
  {label: 'Februari', value: '02'},
  {label: 'Maret', value: '03'},
  {label: 'April', value: '04'},
  {label: 'Mei', value: '05'},
  {label: 'Juni', value: '06'},
  {label: 'Juli', value: '07'},
  {label: 'Agustus', value: '08'},
  {label: 'September', value: '09'},
  {label: 'Oktober', value: '10'},
  {label: 'November', value: '11'},
  {label: 'Desember', value: '12'},
]

const yearOptions = range(2000, parseInt(yearNow + 1)).map(val => ({label: val, value: val}))

const InfoItem = memo(({title, percent, value = 100, loading, color, data}) => {
  return (
    <div className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4 pt-2 pb-3" style={{height: '100%'}}>
      <div className="position-relative">
        <div style={{position: 'absolute', top: 38, left: percent?.toString()?.length > 2 ? 28 :value?.toString()?.length > 2 ? 28 : 32, color: color}}>
          <b style={{fontSize: 14}}>{loading ? '-' : percent ? percent : value }%</b>
        </div>
        <ChartDoughnut
          height={90}
          width={90}
          data={{
            datasets: [
              {
                data: data,
                backgroundColor: ['#0073b9', '#00abda']
              }
            ]
          }}
        />
      </div>
      <div className="text-right px-3 py-2">
        <div>{title}</div>
        {
          loading
            ? <small>Memuat data . . .</small>
            : <b style={{fontSize: 40, fontWeight: 1200, lineHeight: 1}}>{value}</b>
        }
      </div>
    </div>
  )
})

const Pagination = ({
  dataLength,
  onDataLengthChange,
  currentPage,
  totalPage,
  onPaginationChange,
  dataPage,
  dataCount,
  dataNumber,
  size = 'md'
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
        <small className="mb-2">
          Menampilkan {dataNumber} - {dataCount < dataPage ? dataCount : dataPage} dari {dataCount}{" "}
          data
        </small>
        <ReactPaginate
          pageCount={totalPage}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName="pagination pagination-sm justify-content-center"
          pageLinkClassName="page-link"
          breakClassName="page-link"
          previousClassName="page-link"
          nextClassName="page-link"
          activeClassName="page-item active"
          disabledClassName="page-item disabled"
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          onPageChange={onPaginationChange}
          initialPage={currentPage - 1}
          disableInitialCallback={true}
        />
        <small className="mt-1">Tampilkan :</small>
        <SelectDefault defaultValue={dataLength} style={{ width: "150px" }} onChange={onDataLengthChange}>
          <option value="10">10 Data</option>
          <option value="20">20 Data</option>
          <option value="50">50 Data</option>
          <option value="100">100 Data</option>
        </SelectDefault>
    </div>
  )
}

const TablePO = ({month, monthName, year}) => {
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10"
  })

  const getPO = useQuery(['dashboard', 'po', 'page', month, year, paginationConfig], () => DashboardApi.getPembelian({
    bulan_po: month,
    tahun_po: year,
    page: paginationConfig.page,
    per_page: paginationConfig.dataLength
  }))
  

  return (
    <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
      <div className="m-2">
        <b>List Data Purchase Order Bulan {monthName} {year}</b>
      </div>
      <Table>
        <thead>
          <tr>
            <th style={{fontSize: 12}} className="p-1 text-center align-middle">No.</th>
            <th style={{fontSize: 12}} className="p-1 text-center align-middle">Tgl. Purchase Order</th>
            <th style={{fontSize: 12}} className="p-1 text-center align-middle">No. Purchase Order</th>
            <th width={150} style={{fontSize: 12}} className="text-center align-middle">Vendor</th>
            <th style={{fontSize: 12}} className="p-1 text-center align-middle">Total Pembelian</th>
          </tr>
        </thead>
        <tbody>
          {
            getPO.isFetching || !getPO?.data?.data?.length > 0
              ?  <tr>
                    <td colSpan={5}>
                      <DataStatus 
                        loading={getPO.isFetching}
                        text={getPO.isFetching ? 'Memuat data . . .' : getPO.isError ? 'Data gagal dimuat' : 'Tidak ada data'}
                      />
                    </td>
                  </tr>
              : getPO.data?.data?.map((val, index) => (
                <tr>
                  <td style={{fontSize: 12}} className="p-1 align-middle text-center">{TableNumber(paginationConfig?.page, paginationConfig.dataLength, index)}</td>
                  <td style={{fontSize: 12}} className="p-1 align-middle">{val?.tgl_purchase_order ? date.getSlashDMY(new Date(val.tgl_purchase_order)) : '-'}</td>
                  <td style={{fontSize: 12}} className="p-1 align-middle">{val?.no_purchase_order}</td>
                  <td style={{fontSize: 12}} className="p-1 align-middle">{val?.nama_vendor}</td>
                  <td style={{fontSize: 12}} className="p-1 align-middle">{val?.total ? rupiah.getWithComa(val.total) : '-'}</td>
                </tr>
              ))
          }
         
        </tbody>
      </Table>
      <Pagination
        size="sm"
        dataLength={paginationConfig.dataLength}
        dataNumber={
          paginationConfig.page * paginationConfig.dataLength -
          paginationConfig.dataLength +
          1
        }
        dataPage={
          getPO.data?.data_count < paginationConfig.dataLength
            ? getPO.data?.data_count
            : paginationConfig.page * paginationConfig.dataLength
        }
        dataCount={getPO.data?.data_count}
        currentPage={paginationConfig.page}
        totalPage={getPO.data?.total_page}
        onPaginationChange={({ selected }) =>
          setPaginationConfig({
            ...paginationConfig,
            page: selected + 1,
          })
        }
        onDataLengthChange={(e) =>
          setPaginationConfig({
            ...paginationConfig,
            page: 1,
            dataLength: e.target.value,
          })
        }
        />
    </div>
  )
}


const Dashboard = ({setNavbarTitle}) => {
  const [filter, setFilter] = useState({
    month: date.getMonth(new Date()),
    monthName: date.getDetailMonth(new Date()),
    year: date.getYear(new Date())
  })

  const dateSelected = `${filter.year}-${filter.month}-01`

  const getTotalVendor = useQuery(['dashboard', 'total_vendor'], () => DashboardApi.getTotalVendor())
  const getVendorResmi = useQuery(['dashboard', 'vendor-resmi'], () => DashboardApi.getVendorResmi())
  const getCalonVendor = useQuery(['dashboard', 'calon-vendor'], () => DashboardApi.getCalonVendor())
  const getVendorTerbaik = useQuery(['dashboard', 'vendor-terbaik', dateSelected], () => DashboardApi.getVendorTerbaik({bulan_pembelian: filter.month, tahun_pembelian: filter.year}))
  const getTotalPembelian = useQuery(['dashboard', 'total-pembelian', filter.month, filter.year], () => DashboardApi.getTotalPembelian({bulan_po: filter.month, tahun_po: filter.year}))
  const getTotalPembelianTahun = useQuery(['dashboard', 'total-pembelian-tahun', filter.year], () => DashboardApi.getTotalPembelianTahun({tahun_po: filter.year}))
  const getTotalPembelianHari = useQuery(['dashboard', 'total-pembelian-hari', filter.month, filter.year], () => DashboardApi.getTotalPembelianHari({bulan_po: filter.month, tahun_po: filter.year}))

  useEffect(() => {
    setNavbarTitle('Dashboard')
  }, [])
  
  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          month: filter.month, 
          monthName: filter.monthName,
          year: filter.year
        }}
        onSubmit={(val) => {
          setFilter({
            month: val.month,
            monthName: val.monthName,
            year: val.year
          })
        }}
      >
        {({values, setFieldValue, setValues, handleSubmit}) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              
              {/* Bulan */} 
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Bulan</span>
                <div style={{width: 150}}>
                  <Select 
                    noMargin
                    options={monthOptions}
                    defaultValue={monthOptions.find(val => val.value.toString() === values.month.toString())}
                    onChange={(val) => setValues({...values, month: val.value, monthName: val.label})}
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{width: 150}}>
                  <Select 
                    noMargin
                    options={yearOptions.reverse()}
                    defaultValue={yearOptions.find(val => val.value.toString() === values.year.toString())}
                    onChange={(val) => setFieldValue('year', val.value)}
                  />
                </div>
              </div>

              {/* Button */}
              <div>
                <Button text="Filter" className="px-4" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">

        {/* Info Total Vendor */}
        <Col className="mb-3">
          <InfoItem 
            type="default"
            loading={getTotalVendor.isFetching}
            title="Total Vendor"
            percent={100}
            value={getTotalVendor.data?.data ? parseInt(getTotalVendor.data?.data) : '-'}
            data={[100]}
          />
        </Col>

        {/* Info Vendor Resmi */}
        <Col className="mb-3">
          <InfoItem 
            loading={getVendorResmi.isFetching}
            title="Vendor Resmi"
            percent={getVendorResmi.data?.data?.vendor_resmi ? parseInt(getVendorResmi.data?.data?.vendor_resmi) : '-'}
            value={getVendorResmi.data?.data?.jumlah ? parseInt(getVendorResmi.data?.data?.jumlah) : '-'}
            data={[getVendorResmi.data?.data?.vendor_resmi ? parseInt(getVendorResmi.data?.data?.vendor_resmi) : '-', parseInt(100) - parseInt(parseInt(getCalonVendor.data?.data?.calon_vendor) ?? 0)]}
          />
        </Col>
        
        {/* Info Calon Vendor */}
        <Col className="mb-3">
          <InfoItem 
            loading={getCalonVendor.isFetching}
            title="Calon Vendor"
            percent={getCalonVendor.data?.data?.calon_vendor ? parseInt(getCalonVendor.data?.data?.calon_vendor) : '-'}
            value={getCalonVendor.data?.data?.jumlah ? parseInt(getCalonVendor.data?.data?.jumlah) : '-'}
            data={[getCalonVendor.data?.data?.calon_vendor ? parseInt(getCalonVendor.data?.data?.calon_vendor) : '-', parseInt(100) - parseInt(parseInt(getCalonVendor.data?.data?.calon_vendor) ?? 0)]}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6}>

          {/* Vendor Terbaik Section */}
          <div className="p-3 bg-white border rounded shadow-sm mb-3 mt-2">
            <b>Vendor Terbaik {filter.monthName} {filter.year}</b>
            {getVendorTerbaik.isFetching
              ? <div className="d-block text-center my-3">
                  Memuat data . . .
                </div>
              : <>
                  <div className="d-flex align-items-center m-2">
                    <div className="d-flex align-items-center justify-content-center mr-2 rounded" style={{minHeight: 25, minWidth: 25, fontSize: 14 , background: '#FFD700'}}>1</div>
                    <div style={{fontSize: 14}}>{getVendorTerbaik?.data?.data?.[0]?.nama_vendor ?? '-'}</div>
                  </div>
                  <div className="d-flex align-items-center m-2">
                    <div className="d-flex align-items-center justify-content-center mr-2 rounded" style={{minHeight: 25, minWidth: 25, fontSize: 14 , background: '#C0C0C0'}}>2</div>
                    <div style={{fontSize: 14}}>{getVendorTerbaik?.data?.data?.[1]?.nama_vendor ?? '-'}</div>
                  </div>
                  <div className="d-flex align-items-center m-2">
                    <div className="d-flex align-items-center justify-content-center mr-2 rounded" style={{minHeight: 25, minWidth: 25, fontSize: 14 , background: '#CD7F32'}}>3</div>
                    <div style={{fontSize: 14}}>{getVendorTerbaik?.data?.data?.[2]?.nama_vendor ?? '-'}</div>
                  </div>
                </>
            }
          </div>

          {/* Total Pembelian Section */}
          <div className="p-3 bg-white border rounded shadow-sm mb-3 mt-2">
            <b>Total Pembelian {filter.monthName} {filter.year}</b>
            {getTotalPembelian.isFetching
              ? <div className="d-block text-center my-3">
                  Memuat data . . .
                </div>
              : <>
                   <div style={{fontSize: 32}}>
                    <b>{getTotalPembelian.data?.data?.[0]?.total ? rupiah.getWithComa(getTotalPembelian.data?.data?.[0]?.total ) : '-'}</b>
                  </div>
                </>
              }
          </div>

          {/* Table Section */}
          <TablePO 
            month={filter.month} 
            monthName={filter.monthName} 
            year={filter.year}
          />
        </Col>

        <Col md={6}>
          {/* Chart Total Pembelian Bulan */}
          <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
            <div className="text-center">
              <b>Total Pembelian Per Bulan Tahun {filter.year}</b>
            </div>
              {getVendorTerbaik.isFetching
                ? <div className="d-flex align-items-center justify-content-center my-5">
                    Memuat data . . .
                  </div>
                : <>
                    <ChartLine 
                       showLegend={false}
                       data={{
                         labels: monthOptions.map(val => val.label),
                         datasets: [
                           {
                             data: monthOptions.map(val => {
                               const findMonth = getTotalPembelianTahun?.data?.data?.find(el => el?.bulan_po?.toString() === val.value.toString())
 
                               return findMonth?.total ? parseFloat(findMonth.total) : 0
                             }),
                           },
                         ]
                       }}
                       options={{
                         plugins: {
                           tooltip: {
                             callbacks: {
                               label: (val) => rupiah.getWithComa(val.raw)
                             }
                           }
                         },
                         scales: {
                           y: {
                             ticks: {
                               callback: val => rupiah.getWithComa(val)
                             }
                           }
                         }
                       }}
                    />
                  </>
              }
          </div>
          
          {/* Chart Total Pembelian Hari*/}
          <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
            <div className="text-center">
              <b>Total Pembelian Per Hari Bulan {filter.monthName} {filter.year}</b>
            </div>
            {getTotalPembelianHari.isFetching
              ? <div className="d-flex align-items-center justify-content-center my-5">
                  Memuat data . . .
                </div>
              : <>
                  <ChartBarVertical 
                      showLegend={false}
                      data={{
                        labels: getTotalPembelianHari.data?.data?.map(val => val?.harian ?? '-'),
                        datasets: [
                          {
                            data: getTotalPembelianHari.data?.data?.map(val => val?.total ?? 0),
                          },
                        ]
                      }}
                      options={{
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: (val) => rupiah.getWithComa(val.raw)
                            }
                          }
                        },
                        scales: {
                          y: {
                            ticks: {
                              callback: val => rupiah.getWithComa(val)
                            }
                          }
                        }
                      }}
                    />
                </>
              }
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard