// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";

// Components
import { DatePicker, FilterModal, SelectSearch } from "components";
import { DateConvert } from "utilities";

const ModalFilter = ({ toggle, data, setData, dropdown }) => {
  const dataApproval = [
    { label: "Semua", value: undefined },
    {
      value: "APP",
      label: "APPROVED",
    },
    {
      value: "VER",
      label: "VERIFIED",
    },
    {
      value: "REV",
      label: "REVISI",
    },
    {
      value: "REJ",
      label: "REJECT",
    },
    {
      value: "PEN",
      label: "PENDING",
    },
  ];

  // FORM VALUES
  const formInitialValues = {
    tgl_purchase_order_mulai: data.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: data.tgl_purchase_order_selesai,
    tgl_seleksi_vendor_mulai: data.tgl_seleksi_vendor_mulai,
    tgl_seleksi_vendor_selesai: data.tgl_seleksi_vendor_selesai,
    id_item_buaso: data.id_item_buaso,
    id_vendor: data.id_vendor,
    status_approval: data.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    setValues({
      tgl_purchase_order_mulai: undefined,
      tgl_purchase_order_selesai: undefined,
      tgl_seleksi_vendor_mulai: undefined,
      tgl_seleksi_vendor_selesai: undefined,
      id_item_buaso: undefined,
      id_vendor: undefined,
      status_approval: undefined,
    });
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label={<div className="text-capitalize">Tgl. Purchase Order</div>}
            placeholderText={`Pilih Tgl. Purchase Order Produksi`}
            startDate={
              values.tgl_purchase_order_mulai
                ? new Date(values.tgl_purchase_order_mulai)
                : ""
            }
            endDate={
              values.tgl_purchase_order_selesai
                ? new Date(values.tgl_purchase_order_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_purchase_order_mulai`,
                `tgl_purchase_order_selesai`
              )
            }
            monthsShown={2}
          />

          {/* <DatePicker
            selectsRange
            label={<div className="text-capitalize">Tgl. Seleksi Vendor</div>}
            placeholderText={`Pilih Tgl. Seleksi Vendor Produksi`}
            startDate={
              values.tgl_seleksi_vendor_mulai
                ? new Date(values.tgl_seleksi_vendor_mulai)
                : ""
            }
            endDate={
              values.tgl_seleksi_vendor_selesai
                ? new Date(values.tgl_seleksi_vendor_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_seleksi_vendor_mulai`,
                `tgl_seleksi_vendor_selesai`
              )
            }
            monthsShown={2}
          /> */}

          {/* <SelectSearch
            key="1"
            label="Item Barang"
            placeholder="Pilih Item Barang"
            defaultValue={
              values.id_item_buaso &&
              dropdown?.itemBuaso?.find(
                (val) => val.value === values.id_item_buaso
              ).label
            }
            option={dropdown?.itemBuaso}
            onChange={(val) => setFieldValue("id_item_buaso", val.value)}
          /> */}

          <SelectSearch
            key="2"
            label="Vendor"
            placeholder="Pilih Vendor"
            defaultValue={
              values.id_vendor &&
              dropdown?.vendor?.find((val) => val.value === values.id_vendor)
                .label
            }
            option={dropdown?.vendor}
            onChange={(val) => setFieldValue("id_vendor", val.value)}
          />
          <SelectSearch
            key="2"
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={
              values.status_approval &&
              dataApproval?.find((val) => val.value === values.status_approval)
                .label
            }
            option={dataApproval}
            onChange={(val) => setFieldValue("status_approval", val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
