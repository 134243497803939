import React from "react";
import { Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";

const InfoPr = ({ dataInfo }) => {
  const ModalInfoItem = ({ title, value }) => (
    <tr>
      <td>
        <small>{title}</small>
      </td>
      <td className="pl-3 pr-1">
        <small>:</small>
      </td>
      <td>
        <small className="text-capitalize">{value}</small>
      </td>
    </tr>
  );
  return (
    <Row className="mb-3">
      <Col>
        <table>
          <tbody>
            <ModalInfoItem
              title="Tgl. Purchase Request"
              value={
                dataInfo.tgl_purchase_request
                  ? DateConvert(new Date(dataInfo.tgl_purchase_request)).detail
                  : "-"
              }
            />
            <ModalInfoItem
              title="Tgl. Pemakaian"
              value={
                dataInfo.tgl_pemakaian
                  ? DateConvert(new Date(dataInfo.tgl_pemakaian)).detail
                  : "-"
              }
            />
          </tbody>
        </table>
      </Col>
      <Col>
        <table>
          <tbody>
            <ModalInfoItem
              title="No. Purchase Request"
              value={
                dataInfo.no_purchase_request
                  ? dataInfo.no_purchase_request
                  : "-"
              }
            />
            <ModalInfoItem
              title="Keperluan"
              value={dataInfo.keperluan ? dataInfo.keperluan : "-"}
            />
            <ModalInfoItem
              title="Proyek"
              value={dataInfo.nama_proyek ? dataInfo.nama_proyek : "-"}
            />
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default InfoPr;
