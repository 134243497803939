export const formInitialValues = (data) => {
  return Object({
    id_seleksi_vendor_aset: data?.id_seleksi_vendor_aset ?? undefined,
    tgl_seleksi_vendor_aset: data?.tgl_seleksi_vendor_aset ?? undefined,
    no_seleksi_vendor_aset: data?.no_seleksi_vendor_aset ?? '',
    harga_kesepakatan: data?.harga_kesepakatan ?? 0,
    id_vendor: data?.id_vendor ?? undefined,
    nama_vendor: data?.nama_vendor ?? '',
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    tglPermintaanAset: data?.tgl_permintaan_aset ?? undefined,
    noPermintaanAset: data?.no_permintaan_aset ?? '',
    catatanPermintaan: data?.catatan_permintaan ?? '',
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    idEntitasAset: data?.id_entitas_aset ?? undefined,
    namaEntitasAset: data?.nama_entitas_aset ?? '',
    idGrupAset: data?.id_grup_aset ?? undefined,
    namaGrupAset: data?.nama_grup_aset ?? '',
    idKategoriAset: data?.id_kategori_aset ?? undefined,
    namaKategoriAset: data?.nama_kategori_aset ?? '',
    idJenisAset: data?.id_jenis_aset ?? undefined,
    namaJenisAset: data?.nama_jenis_aset ?? '',
    idUnitProduksi: data?.id_unit_organisasi ?? undefined,
    namaUnitProduksi: data?.nama_unit_organisasi ?? '',
    keperluan: data?.keperluan ?? undefined,
    keperluanLabel: data?.keperluan ?? '',
    metodePengadaan: data?.metode_pengadaan ?? undefined,
    metodePengadaanLabel: data?.metode_pengadaan ?? '',
    namaAset: data?.nama_aset ?? '',
    keterangan: data?.keterangan ?? '',
    isSeleksi: data?.is_seleksi ?? false,
  });
};

export const mappingDetailData = (data) => {
  return Object({
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? '',
    catatan_permintaan: data?.catatan_permintaan ?? '',
    detail: data?.detail
      ? data.detail.map((val) => ({
          tglPermintaanAset: data?.tgl_permintaan_aset ?? undefined,
          noPermintaanAset: data?.no_permintaan_aset ?? '',
          catatanPermintaan: data?.catatan_permintaan ?? '',
          id_permintaan_aset_detail:
            val?.id_permintaan_aset_detail ?? undefined,
          idEntitasAset: val?.id_entitas_aset ?? undefined,
          namaEntitasAset: val?.nama_entitas_aset ?? '',
          idGrupAset: val?.id_grup_aset ?? undefined,
          namaGrupAset: val?.nama_grup_aset ?? '',
          idKategoriAset: val?.id_kategori_aset ?? undefined,
          namaKategoriAset: val?.nama_kategori_aset ?? '',
          idJenisAset: val?.id_jenis_aset ?? undefined,
          namaJenisAset: val?.nama_jenis_aset ?? '',
          idUnitProduksi: val?.id_unit_organisasi ?? undefined,
          namaUnitProduksi: val?.nama_unit_organisasi ?? '',
          keperluan: val?.keperluan ?? undefined,
          keperluanLabel: val?.keperluan ?? '',
          metodePengadaan: val?.metode_pengadaan ?? undefined,
          metodePengadaanLabel: val?.metode_pengadaan ?? '',
          namaAset: val?.nama_aset ?? '',
          keterangan: val?.keterangan ?? '',
          isSeleksi: val?.is_seleksi ?? false,
          file:
            val?.file?.length > 0
              ? val?.file?.map((val) => val?.path_file)
              : [],
        }))
      : [],
  });
};
