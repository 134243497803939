import {
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  TextArea,
  TextEditor,
} from 'components';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DateConvert } from 'utilities';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { ClosePurchaseOrderAsetApi } from 'api';

export const InfoSection = ({ isSpk, action }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML('<p></p>'))
    )
  );

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    ClosePurchaseOrderAsetApi.getNomor({ tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          no_close_purchase_order_aset: no,
          tgl_close_purchase_order_aset: tanggal,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          no_close_purchase_order_aset: '',
          tgl_close_purchase_order_aset: null,
        });
        window.alert('Nomor gagal dimuat');
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(values?.lain_lain ?? '<p></p>')
        )
      )
    );
  }, [values]);
  return (
    <>
      <Row>
        <Col lg="4">
          <InfoItemHorizontal
            label="Tgl. Purchase Order Aset"
            text={
              values.tgl_purchase_order_aset
                ? DateConvert(new Date(values?.tgl_purchase_order_aset))?.detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Purchase Order Aset"
            text={values?.no_purchase_order_aset ?? '-'}
          />
        </Col>
        <Col lg="4">
          <InfoItemHorizontal label="Kepada" />
          {values.nama_vendor ?? '-'}
          <br />
          {values.alamat_vendor ?? '-'}
          <br />
          {values.contact_person ?? '-'}
        </Col>
        <Col lg="4">
          <InfoItemHorizontal label="Keterangan" />
          {values.keterangan ?? '-'}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <DatePicker
            label="Tgl. Close Purchase Order Aset"
            disabled={action === 'read'}
            onChange={(date) => {
              getNomorHandler(date);
            }}
            selected={
              values?.tgl_close_purchase_order_aset
                ? new Date(values?.tgl_close_purchase_order_aset)
                : null
            }
            error={
              Boolean(errors?.tgl_close_purchase_order_aset) &&
              touched?.tgl_close_purchase_order_aset
            }
            errorText={
              Boolean(errors?.tgl_close_purchase_order_aset) &&
              touched?.tgl_close_purchase_order_aset &&
              errors?.tgl_close_purchase_order_aset
            }
          />
        </Col>
        <Col>
          <Input
            label="No. Close Purchase Order Aset"
            disabled
            value={
              isNomorLoading
                ? 'Memuat Data..'
                : values?.no_close_purchase_order_aset
            }
            placeholder="Pilih tanggal terlebih dahulu"
            error={
              Boolean(errors?.no_close_purchase_order_aset) &&
              touched?.no_close_purchase_order_aset
            }
            errorText={
              Boolean(errors?.no_close_purchase_order_aset) &&
              touched?.no_close_purchase_order_aset &&
              errors?.no_close_purchase_order_aset
            }
          />
        </Col>
      </Row>
    </>
  );
};
