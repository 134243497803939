import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  Alert,
  BackButton,
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
  DatePicker,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { SeleksiVendorApi } from "../../../../api";

const DetailSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    SeleksiVendorApi.getSingle({ id_seleksi_vendor: id })
      .then(({ data }) => {
        setDataSeleksiVendor(data.data);
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Detail Seleksi Vendor");
    getInitialData();

    return () => {};
  }, []);

  const PageContent = () => {
    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <Row>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="Nama Vendor"
                    value={dataSeleksiVendor.nama_vendor ? dataSeleksiVendor.nama_vendor : "-"}
                  />
                  <InfoItem
                    title="Tgl. Purchase Request"
                    value={
                      dataSeleksiVendor.tgl_purchase_request
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Pemakaian"
                    value={
                      dataSeleksiVendor.tgl_pemakaian
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Seleksi Vendor"
                    value={
                      dataSeleksiVendor.tgl_seleksi_vendor
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_seleksi_vendor)).detail
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="No. Purchase Request"
                    value={
                      dataSeleksiVendor.no_purchase_request
                        ? dataSeleksiVendor.no_purchase_request
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Keperluan"
                    value={dataSeleksiVendor.keperluan ? dataSeleksiVendor.keperluan : "-"}
                  />
                  <InfoItem
                    title="No. Seleksi Vendor"
                    value={
                      dataSeleksiVendor.no_seleksi_vendor
                        ? dataSeleksiVendor.no_seleksi_vendor
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
          </Row>
          <hr />
        </>
      );
    };

    const ListItemPurchaseRequestSection = () => {
      const TableListItemPurchaseRequest = () => (
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty PR</Th>
              <Th>Qty Order</Th>
              <Th>Harga Perkiraan</Th>
            </Tr>
          </THead>
          <TBody>
            {dataSeleksiVendor.pr_detail.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>
                  <div style={{ width: "200px" }}>
                    {/* {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                      ? `${val.nama_item} (${val.pj} X ${val.lb})`
                      : val.dimensi === "1"
                      ? `${val.nama_item} (${val.pj})`
                      : val.dimensi === "0"
                      ? val.nama_item
                      : ""} */}
                    {val.nama_item}
                  </div>
                </Td>
                <Td>{val.satuan_beli}</Td>
                <Td textRight>{val.qty_pr}</Td>
                <Td textRight>{val.qty_ordered}</Td>
                <Td textRight>
                  {RupiahConvert(parseFloat(val.harga_perkiraan_sendiri).toString()).getWithComa}
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Purchase Request</b>
          </div>
          {dataSeleksiVendor.pr_detail ? (
            dataSeleksiVendor.pr_detail.length > 0 ? (
              <TableListItemPurchaseRequest />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const ListItemSeleksiVendorSection = () => {
      const TableListItemSeleksiVendor = () => {
        let total = 0;

        return (
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <Th>Satuan Beli</Th>
                <Th>Qty Order</Th>
                <Th>Harga Kesepakatan</Th>
                <Th>Jumlah</Th>
              </Tr>
            </THead>
            <TBody>
              {dataSeleksiVendor.detail.map((val, index) => {
                total = total + val.harga_kesepakatan * val.qty_order;

                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item}</TdFixed>
                    <Td>
                      <div style={{ width: "200px" }}>
                        {/* {val.dimensi === "3"
                          ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                          : val.dimensi === "2"
                          ? `${val.nama_item} (${val.pj} X ${val.lb})`
                          : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                          ? val.nama_item
                          : ""} */}
                        {val.nama_item}
                      </div>
                    </Td>
                    <Td>{val.satuan_beli}</Td>
                    <Td textRight>{val.qty_order}</Td>
                    <Td textRight>
                      {RupiahConvert(parseFloat(val.harga_kesepakatan).toString()).getWithComa}
                    </Td>
                    <Td textRight>
                      {
                        RupiahConvert(
                          (val.qty_order * parseFloat(val.harga_kesepakatan)).toString()
                        ).getWithComa
                      }
                    </Td>
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={6} textRight>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  <b>{RupiahConvert(total.toString()).getWithComa}</b>
                </Td>
              </Tr>
            </TBody>
          </Table>
        );
      };

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Seleksi Vendor</b>
          </div>
          {dataSeleksiVendor.detail ? (
            dataSeleksiVendor.detail.length > 0 ? (
              <TableListItemSeleksiVendor />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    return (
      <>
        <InfoSection />
        <ListItemPurchaseRequestSection />
        <ListItemSeleksiVendorSection />
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Data Seleksi Vendor</b>
        <BackButton onClick={() => history.push("/transaksi/seleksi-vendor")} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        ) : (
          <PageContent />
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailSeleksiVendor;
