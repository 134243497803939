import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

import {
  SelectSearch,
  ActionButton,
  Table,
  Alert,
  DataStatus,
  DeleteButton,
  DeleteModal,
} from "../../../../components";

import { ItemBahanApi, ItemSubconApi, ItemUpahApi } from "../../../../api/Master/VendorItem";

const FormListItem = ({ idVendor, jenisVendor, setAlertHandler }) => {
  let history = useHistory();
  const location = useLocation();
  const routerState = location.state;
  const [isLoading, setIsLoading] = useState(true);

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [dataKelompokBahan, setDataKelompokBahan] = useState([]);
  const [dataItemUpah, setDataItemUpah] = useState([]);
  const [dataKelompokSubcon, setDataKelompokSubcon] = useState([]);
  const [isLoadingFormBahan, setIsLoadingFormBahan] = useState({
    jenis: false,
    item: false,
  });
  const [isLoadingFormSubcon, setIsLoadingFormSubcon] = useState({
    item: false,
  });
  const [dataFormBahan, setDataFormBahan] = useState({
    jenis: [],
    item: [],
  });
  const [dataFormSubcon, setDataFormSubcon] = useState({
    jenis: [],
    item: [],
  });
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataSubcon, setDataSubcon] = useState([]);

  const [isDeleteData, setIsDeleteData] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const getDataJenisBahan = (id) => {
    setIsLoadingFormBahan({
      jenis: true,
      item: true,
    });

    ItemBahanApi.getJenis(id)
      .then((res) =>
        setDataFormBahan({
          ...dataFormBahan,
          jenis: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingFormBahan({
          jenis: false,
          item: false,
        })
      );
  };

  const getDataItemBahan = (id) => {
    setIsLoadingFormBahan({
      item: true,
    });

    ItemBahanApi.getItem(id)
      .then((res) =>
        setDataFormBahan({
          ...dataFormBahan,
          item: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingFormBahan({
          item: false,
        })
      );
  };

  const getDataItemSubcon = (id) => {
    setIsLoadingFormSubcon({
      item: true,
    });

    ItemSubconApi.getItem(id)
      .then((res) =>
        setDataFormSubcon({
          item: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingFormSubcon({
          item: false,
        })
      );
  };

  const getDataBahan = () => {
    ItemBahanApi.get(idVendor)
      .then((res) => {
        setDataBahan(res.data.data);
      })
      .catch((err) => alert(err));
  };

  const getDataUpah = () => {
    ItemUpahApi.get(idVendor)
      .then((res) => setDataUpah(res.data.data))
      .catch((err) => alert(err));
  };

  const getDataSubcon = () => {
    ItemSubconApi.get(idVendor)
      .then((res) => setDataSubcon(res.data.data))
      .catch((err) => alert(err));
  };

  const getInitialData = () => {
    Axios.all([ItemBahanApi.getKelompok(), ItemUpahApi.getItem(), ItemSubconApi.getKelompok()])
      .then(
        Axios.spread((bahan, upah, subcon) => {
          setDataKelompokBahan(bahan.data.data);
          setDataItemUpah(upah.data.data);
          setDataKelompokSubcon(subcon.data.data);
        })
      )
      .catch((err) => alert(err));
  };

  useEffect(() => {
    setIsLoading(true);

    getInitialData();
    getDataBahan();
    getDataSubcon();
    getDataUpah();

    setIsLoading(false);
  }, []);

  const formInitialValuesBahan = {
    id_vendor: idVendor,
    id_kelompok: "",
    id_jenis: "",
    id_item_buaso: "",
  };

  const formValidationSchemaBahan = Yup.object().shape({
    id_kelompok: Yup.string().required("Pilih Kelompok Bahan"),
    id_jenis: Yup.string().required("Pilih Jenis Bahan"),
    id_item_buaso: Yup.string().required("Pilih Item Bahan"),
  });

  const formSubmitHandlerBahan = (values) => {
    const finalValues = {
      ...values,
      id_item_bahan: values.id_item_buaso,
    };

    ItemBahanApi.create(finalValues)
      .then((res) =>
        setAlertHandler({
          show: true,
          variant: "success",
          text: `Tambah Bahan Berhasil!`,
        })
      )
      .catch((err) =>
        setAlertHandler({
          show: true,
          variant: "danger",
          text: `Tambah Bahan gagal! (${err})`,
        })
      )
      .finally(() => {
        setShowAlert(true);

        getDataBahan();
      });
  };

  const formInitialValuesUpah = {
    id_vendor: idVendor,
    id_item_buaso: "",
  };

  const formValidationSchemaUpah = Yup.object().shape({
    id_item_buaso: Yup.string().required("Pilih Item Upah"),
  });

  const formSubmitHandlerUpah = (values) => {
    const finalValues = {
      ...values,
      id_item_upah: values.id_item_buaso,
    };

    ItemUpahApi.create(finalValues)
      .then((res) =>
        setAlertHandler({
          show: true,
          variant: "success",
          text: `Tambah Upah Berhasil!`,
        })
      )
      .catch((err) =>
        setAlertHandler({
          show: true,
          variant: "danger",
          text: `Tambah Upah gagal! (${err})`,
        })
      )
      .finally(() => {
        setShowAlert(true);

        getDataUpah();
      });
  };

  const formInitialValuesSubcon = {
    id_vendor: idVendor,
    id_item_buaso: "",
  };

  const formValidationSchemaSubcon = Yup.object().shape({
    id_kelompok: Yup.string().required("Pilih Kelompok Subcon"),
    id_item_buaso: Yup.string().required("Pilih Item Subcon"),
  });

  const formSubmitHandlerSubcon = (values) => {
    const finalValues = {
      ...values,
      id_item_subcon: values.id_item_buaso,
    };

    ItemSubconApi.create(finalValues)
      .then((res) =>
        setAlertHandler({
          show: true,
          variant: "success",
          text: `Tambah Subcon Berhasil!`,
        })
      )
      .catch((err) =>
        setAlertHandler({
          show: true,
          variant: "danger",
          text: `Tambah Subcon gagal! (${err})`,
        })
      )
      .finally(() => {
        setShowAlert(true);

        getDataSubcon();
      });
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);
    // SET DATA ID YANG DIHAPUS
    let deleteValue = {};
    if (deleteData.tipe_list == "bahan") {
      deleteValue = { id_item_bahan_vendor: deleteData.val.id_item_bahan_vendor };
    } else if (deleteData.tipe_list == "upah") {
      deleteValue = { id_item_upah_vendor: deleteData.val.id_item_upah_vendor };
    } else if (deleteData.tipe_list == "subcon") {
      deleteValue = { id_item_subcon_vendor: deleteData.val.id_item_subcon_vendor };
    }

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      if (deleteData.tipe_list == "bahan") {
        ItemBahanApi.delete(deleteValue)
          .then(() => {
            // KONFIGURASI ALERT
            setAlertHandler({
              show: true,
              variant: "primary",
              text: "Hapus Item berhasil!",
            });
          })
          .catch((err) => {
            // KONFIGURASI ALERT
            setAlertHandler({
              show: true,
              variant: "danger",
              text: `Hapus Item gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setIsDeleteData(false);
            // TAMPIL ALERT
            setShowAlert(true);
            // FETCH DATA DARI SERVER
            getDataBahan();
          });
      } else if (deleteData.tipe_list == "upah") {
        ItemUpahApi.delete(deleteValue)
          .then(() => {
            // KONFIGURASI ALERT
            setAlertHandler({
              show: true,
              variant: "primary",
              text: "Hapus Item berhasil!",
            });
          })
          .catch((err) => {
            // KONFIGURASI ALERT
            setAlertHandler({
              show: true,
              variant: "danger",
              text: `Hapus Item gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setIsDeleteData(false);
            // TAMPIL ALERT
            setShowAlert(true);
            // FETCH DATA DARI SERVER
            getDataUpah();
          });
      } else if (deleteData.tipe_list == "subcon") {
        ItemSubconApi.delete(deleteValue)
          .then(() => {
            // KONFIGURASI ALERT
            setAlertHandler({
              show: true,
              variant: "primary",
              text: "Hapus Item berhasil!",
            });
          })
          .catch((err) => {
            // KONFIGURASI ALERT
            setAlertHandler({
              show: true,
              variant: "danger",
              text: `Hapus Item gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setIsDeleteData(false);
            // TAMPIL ALERT
            setShowAlert(true);
            // FETCH DATA DARI SERVER
            getDataSubcon();
          });
      }
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      />
    );
  };
  console.log(dataFormBahan);
  return (
    <>
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div>
          <Alert
            show={showAlert}
            showCloseButton={true}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setShowAlert(false)}
          />

          {jenisVendor.map((value) => {
            if (value.jenis_vendor == "Supplier") {
              return (
                <>
                  <Formik
                    initialValues={formInitialValuesBahan}
                    validationSchema={formValidationSchemaBahan}
                    onSubmit={formSubmitHandlerBahan}
                  >
                    {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => (
                      <form className="py-2" onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={6} lg={3}>
                            <SelectSearch
                              label="Kelompok Bahan"
                              name="id_kelompok"
                              placeholder="Pilih Kelompok Bahan"
                              onChange={(val) => {
                                setFieldValue("id_kelompok", val.value);
                                setFieldValue("nama_kelompok", val.label);
                                setFieldValue("id_jenis", "");
                                setFieldValue("id_item", "");
                                getDataJenisBahan(val.value);
                              }}
                              option={dataKelompokBahan.map((val) => {
                                return {
                                  value: val.id_kelompok,
                                  label: val.nama_kelompok,
                                };
                              })}
                              error={errors.id_kelompok && touched.id_kelompok && true}
                              errorText={
                                errors.id_kelompok && touched.id_kelompok && errors.id_kelompok
                              }
                            />
                          </Col>

                          <Col sm={6} lg={3}>
                            <SelectSearch
                              label="Jenis Bahan"
                              name="id_jenis"
                              placeholder="Pilih Jenis Bahan"
                              onChange={(val) => {
                                setFieldValue("id_jenis", val.value);
                                setFieldValue("nama_jenis", val.label);
                                setFieldValue("id_item", "");
                                getDataItemBahan(val.value);
                              }}
                              option={dataFormBahan.jenis.map((val) => {
                                return {
                                  value: val.id_jenis,
                                  label: val.nama_jenis,
                                };
                              })}
                              error={errors.id_jenis && touched.id_jenis && true}
                              errorText={errors.id_jenis && touched.id_jenis && errors.id_jenis}
                              loading={isLoadingFormBahan.jenis}
                              isDisabled={values.id_kelompok ? false : true}
                            />
                          </Col>

                          <Col sm={6} lg={3}>
                            <SelectSearch
                              label="Item Bahan"
                              name="id_item_buaso"
                              placeholder="Pilih Item Bahan"
                              onChange={(val) => {
                                setFieldValue("id_item_buaso", val.value);
                                setFieldValue("nama_item", val.label);
                              }}
                              option={dataFormBahan.item.map((val) => {
                                return {
                                  value: val.id_item_buaso,
                                  label:
                                    val.pj && val.lb && val.tb !== "0"
                                      ? `${val.nama_item} || ${parseInt(val.pj)} X ${parseInt(
                                          val.lb
                                        )} X ${parseInt(val.tb)}`
                                      : val.nama_item,
                                };
                              })}
                              error={errors.id_item_buaso && touched.id_item_buaso && true}
                              errorText={
                                errors.id_item_buaso &&
                                touched.id_item_buaso &&
                                errors.id_item_buaso
                              }
                              loading={isLoadingFormBahan.item}
                              isDisabled={values.id_jenis ? false : true}
                            />
                          </Col>

                          <Col sm={6} lg={3}>
                            <br></br>
                            <ActionButton
                              type="submit"
                              variant="primary"
                              text="Tambah"
                              loading={isSubmitting}
                            />
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>

                  <small>List Item Bahan</small>
                  <Table>
                    <thead>
                      <tr>
                        <th>Kode Item Bahan</th>
                        <th>Nama Item Bahan</th>
                        <th>Kelompok Bahan</th>
                        <th>Jenis Bahan</th>
                        <th>Tipe Bahan</th>
                        <th>Satuan Pakai</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dataBahan.map((val, index) => (
                        <tr key={index}>
                          <td>{val.kode_item}</td>
                          <td>{val.nama_item}</td>
                          <td>{val.nama_kelompok}</td>
                          <td>{val.nama_jenis}</td>
                          <td>{val.tipe}</td>
                          <td>{val.satuan_pakai}</td>
                          <td className="d-flex justify-content-center">
                            <DeleteButton
                              onClick={() => {
                                setDeleteData({ tipe_list: "bahan", val });
                                setIsDeleteData(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              );
            } else if (value.jenis_vendor == "Mandor") {
              return (
                <>
                  <Formik
                    initialValues={formInitialValuesUpah}
                    validationSchema={formValidationSchemaUpah}
                    onSubmit={formSubmitHandlerUpah}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form className="py-2" onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={6}>
                            <SelectSearch
                              label="Item Upah"
                              name="id_item_buaso"
                              placeholder="Pilih Item Upah"
                              onChange={(val) => {
                                setFieldValue("id_item_buaso", val.value);
                                setFieldValue("nama_item", val.label);
                              }}
                              option={dataItemUpah.map((val) => {
                                return {
                                  value: val.id_item_buaso,
                                  label: val.nama_item,
                                };
                              })}
                              error={errors.id_item_buaso && touched.id_item_buaso && true}
                              errorText={
                                errors.id_item_buaso &&
                                touched.id_item_buaso &&
                                errors.id_item_buaso
                              }
                            />
                          </Col>

                          <Col sm={6}>
                            <br></br>
                            <ActionButton
                              type="submit"
                              variant="primary"
                              text="Tambah"
                              loading={isSubmitting}
                            />
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>

                  <small>List Item Upah</small>
                  <Table>
                    <thead>
                      <tr>
                        <th>Kode Item Bahan</th>
                        <th>Nama Item Bahan</th>
                        <th>Satuan Pakai</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dataUpah.map((val, index) => (
                        <tr key={index}>
                          <td>{val.kode_item}</td>
                          <td>{val.nama_item}</td>
                          <td>{val.nama_satuan}</td>
                          <td className="d-flex justify-content-center">
                            <DeleteButton
                              onClick={() => {
                                setDeleteData({ tipe_list: "upah", val });
                                setIsDeleteData(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              );
            } else if (value.jenis_vendor == "Subcon") {
              return (
                <>
                  <Formik
                    initialValues={formInitialValuesSubcon}
                    validationSchema={formValidationSchemaSubcon}
                    onSubmit={formSubmitHandlerSubcon}
                  >
                    {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => (
                      <form className="py-2" onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={6} lg={3}>
                            <SelectSearch
                              label="Kelompok Subcon"
                              name="id_kelompok"
                              placeholder="Pilih Kelompok Subcon"
                              onChange={(val) => {
                                setFieldValue("id_kelompok", val.value);
                                setFieldValue("nama_kelompok", val.label);
                                setFieldValue("id_item", "");
                                getDataItemSubcon(val.value);
                              }}
                              option={dataKelompokSubcon.map((val) => {
                                return {
                                  value: val.id_kelompok,
                                  label: val.nama_kelompok,
                                };
                              })}
                              error={errors.id_kelompok && touched.id_kelompok && true}
                              errorText={
                                errors.id_kelompok && touched.id_kelompok && errors.id_kelompok
                              }
                            />
                          </Col>

                          <Col sm={6}>
                            <SelectSearch
                              label="Item Subcon"
                              name="id_item_buaso"
                              placeholder="Pilih Item Subcon"
                              onChange={(val) => {
                                setFieldValue("id_item_buaso", val.value);
                                setFieldValue("nama_item", val.label);
                              }}
                              option={dataFormSubcon.item.map((val) => {
                                return {
                                  value: val.id_item_buaso,
                                  label: val.nama_item,
                                };
                              })}
                              error={errors.id_item_buaso && touched.id_item_buaso && true}
                              errorText={
                                errors.id_item_buaso &&
                                touched.id_item_buaso &&
                                errors.id_item_buaso
                              }
                              loading={isLoadingFormSubcon.item}
                              isDisabled={values.id_kelompok ? false : true}
                            />
                          </Col>

                          <Col sm={6} lg={3}>
                            <br></br>
                            <ActionButton
                              type="submit"
                              variant="primary"
                              text="Tambah"
                              loading={isSubmitting}
                            />
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>

                  <small>List Item Subcon</small>
                  <Table>
                    <thead>
                      <tr>
                        <th>Kode Item Subcon</th>
                        <th>Nama Item Subcon</th>
                        <th>Kelompok Subcon</th>
                        <th>Satuan Pakai</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dataSubcon.map((val, index) => (
                        <tr key={index}>
                          <td>{val.kode_item}</td>
                          <td>{val.nama_item}</td>
                          <td>{val.nama_kelompok}</td>
                          <td>{val.nama_satuan}</td>
                          <td className="d-flex justify-content-center">
                            <DeleteButton
                              onClick={() => {
                                setDeleteData({ tipe_list: "subcon", val });
                                setIsDeleteData(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              );
            }
          })}
        </div>
      )}

      <div className="d-flex justify-content-end">
        <ActionButton
          type="submit"
          variant="secondary"
          text="Kembali ke Tabel"
          onClick={() => {
            history.push("/master/vendor", {
              ...routerState,
              variant: "primary",
              text: "Input Vendor berhasil",
              status: true,
            });
          }}
        />
      </div>

      <HapusModal />
    </>
  );
};

export default FormListItem;
