import * as Yup from 'yup';

export const formValidationSchema = Yup.object().shape({
  tgl_close_purchase_order_aset: Yup.string()
    .required('Pilih tanggal')
    .nullable(),
  no_close_purchase_order_aset: Yup.string()
    .required('Pilih tanggal untuk menentukan nomor')
    .nullable(),
});
