// React
import { useEffect, useState } from "react";

// API
import { HargaPerkiraanSendiriApi } from "api";

// Components
import {
  ActionButton,
  Alert,
  CRUDLayout,
  DataStatus,
  ThFixed,
  Tr,
  NumberFormat,
  Pagination,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  UpdateButton,
  UpdateModal,
  InputCurrency,
} from "components";
import { Modal } from "react-bootstrap";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// View Functions
import { Timestamp } from "../functions";

const TabItem = ({ q, filter, value }) => {
  // States
  const [dataFilter, setDataFilter] = useState({
    page: 1,
    per_page: 10,
  });
  const [page, setPage] = useState({
    loading: false,
    status: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: `Hasil Pencarian : ${q}`,
  });
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [modal, setModal] = useState({
    show: false,
    data: {},
  });

  const toggleModal = () => setModal({ ...modal, show: !modal.show });

  const mappingData = (value) => {
    const buaso = (value) => {
      switch (value) {
        case "1":
          return "B";
        case "2":
          return "U";
        case "3":
          return "A";
        case "4":
          return "S";
        case "5":
          return "O";
        default:
          return null;
      }
    };

    return value.map((val) => {
      return {
        ...val,
        buaso: buaso(val.id_buaso),
      };
    });
  };

  const getData = () => {
    setPage({
      loading: true,
      status: false,
    });

    HargaPerkiraanSendiriApi.page({
      ...dataFilter,
      q,
      id_buaso: Boolean(filter && filter !== "") ? filter : undefined,
    })
      .then((res) => {
        setData(mappingData(res.data.data));
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    getData(); // GET DATA DARI SERVER

    // eslint-disable-next-line
  }, [q, filter, dataFilter]);

  const DataModal = () => {
    const [isFocusBeli, setIsFocusBeli] = useState(true);
    const List = ({ label, children }) => (
      <Tr>
        <Td className="text-nowrap text-capitalize">{label}</Td>
        <Td className="mx-50">:</Td>
        <Td>{children}</Td>
      </Tr>
    );

    const HpsBeliChange = (value, values, setValues) => {
      const konversi = parseFloat(modal.data.nilai_konversi);

      const hps_pakai = value / konversi;
      setValues({
        ...values,
        hps_beli: value || "",
        hps_pakai: hps_pakai ?? "",
      });
    };

    const HpsPakaiChange = (value, values, setValues) => {
      const konversi = parseFloat(modal.data.nilai_konversi);
      const hps_beli = value * konversi;
      setValues({
        ...values,
        hps_beli: hps_beli || "",
        hps_pakai: value ?? "",
      });
    };

    return (
      <UpdateModal
        show={modal.show}
        onHide={toggleModal}
        title="Harga Perkiraan Sendiri">
        <Formik
          // enableReinitialize
          initialValues={{
            id_item_hps: modal.data.id_item,
            hps_beli: parseFloat(modal.data.hps_beli),
            hps_pakai: parseFloat(modal.data.hps_pakai),
            buaso: modal.data.buaso,
            tgl_harga_perkiraan_sendiri: `${
              DateConvert(new Date()).default
            } ${Timestamp(new Date())}`,
          }}
          validationSchema={Yup.object().shape({
            hps_beli: Yup.string()
              .required(`Harga Perkiraan Sendiri Per Satuan Beli wajib diisi`)
              .nullable(),
            hps_pakai: Yup.string()
              .required(`Harga Perkiraan Sendiri Per Satuan Pakai wajib diisi`)
              .nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            HargaPerkiraanSendiriApi.update(values)
              .then(() => {
                setAlertConfig({
                  show: true,
                  variant: "primary",
                  text: "Ubah data berhasil!",
                });
              })
              .catch(() => {
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: "Ubah data gagal!",
                });
              })
              .finally(() => {
                toggleModal();
                setSubmitting(false);
                getData();
              });
          }}>
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setValues,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <table>
                  <TBody>
                    <List label="Kode Item">{modal.data.kode_item ?? "-"}</List>
                    <List label="Nama Item">{modal.data.nama_item ?? "-"}</List>
                    <List label="Grup BUASO">
                      {modal.data.grup_buaso ?? "-"}
                    </List>
                    <List label="Satuan Beli">
                      {modal.data.satuan_beli ?? "-"}
                    </List>
                    <List label="Satuan Pakai">
                      {modal.data.satuan_pakai ?? "-"}
                    </List>
                    <List label="Tgl. Update Terakhir">
                      {modal.data.tgl_harga_perkiraan_sendiri
                        ? `${
                            DateConvert(
                              new Date(modal.data.tgl_harga_perkiraan_sendiri)
                            ).defaultDMY
                          } ${Timestamp(
                            modal.data.tgl_harga_perkiraan_sendiri
                          )}`
                        : "-"}
                    </List>
                  </TBody>
                </table>
                <hr />

                <div onFocus={() => setIsFocusBeli(true)}>
                  <InputCurrency
                    label="Harga Perkiraan Sendiri Per Satuan Beli"
                    value={values.hps_beli}
                    defaultValue={values.hps_beli}
                    onChange={(value) => {
                      if (isFocusBeli) {
                        HpsBeliChange(value, values, setValues);
                      }
                    }}
                    decimalScale={99}
                    error={errors.hps_beli && touched.hps_beli && true}
                    errorText={errors.hps_beli}
                  />
                </div>

                <div onFocus={() => setIsFocusBeli(false)}>
                  <InputCurrency
                    label="Harga Perkiraan Sendiri Per Satuan Pakai"
                    value={values.hps_pakai}
                    defaultValue={values.hps_pakai}
                    onChange={(value) => {
                      if (!isFocusBeli) {
                        HpsPakaiChange(value, values, setValues);
                      }
                    }}
                    decimalScale={99}
                    error={errors.hps_pakai && touched.hps_pakai && true}
                    errorText={errors.hps_pakai}
                  />
                </div>
                {/* <NumberFormat
									label={`Harga Perkiraan Sendiri Per Satuan ${value}`}
									value={values.hps}
									defaultValue={values.hps}
									onChange={value => setFieldValue('hps', value ?? "")}
									decimalScale={2}
									prefix="Rp"
									className='text-right'
									error={Boolean(errors.hps && touched.hps) && true}
									errorText={errors.hps}
								/> */}
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <ThFixed>Grup BUASO</ThFixed>
              <Th className="text-capitalize" style={{ width: "100px" }}>
                Satuan Beli
              </Th>
              <Th className="text-capitalize" style={{ width: "150px" }}>
                HPS Per satuan Beli
              </Th>
              <Th className="text-capitalize" style={{ width: "100px" }}>
                Satuan Pakai
              </Th>
              <Th className="text-capitalize" style={{ width: "150px" }}>
                HPS Per satuan Pakai
              </Th>
              <ThFixed>Tgl. Update Terakhir</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>
                    {TableNumber(dataFilter.page, dataFilter.per_page, index)}
                  </TdFixed>
                  <TdFixed className="text-center">
                    {val.kode_item ? val.kode_item : "-"}
                  </TdFixed>
                  <Td>{val.nama_item ? val.nama_item : "-"}</Td>
                  <Td>{val.grup_buaso ? val.grup_buaso : "-"}</Td>
                  <Td>{val.satuan_beli ? val.satuan_beli : "-"}</Td>
                  <Td className="text-right text-nowrap">
                    {val.hps_beli
                      ? RupiahConvert(String(parseFloat(val.hps_beli)))
                          .getWithComa
                      : "Rp 0"}
                  </Td>
                  <Td>{val.satuan_pakai ? val.satuan_pakai : "-"}</Td>
                  <Td className="text-right text-nowrap">
                    {val.hps_pakai
                      ? RupiahConvert(String(parseFloat(val.hps_pakai)))
                          .getWithComa
                      : "Rp 0"}
                  </Td>
                  <TdFixed>
                    {val.tgl_harga_perkiraan_sendiri
                      ? `${
                          DateConvert(new Date(val.tgl_harga_perkiraan_sendiri))
                            .defaultDMY
                        } ${Timestamp(val.tgl_harga_perkiraan_sendiri)}`
                      : "-"}
                  </TdFixed>
                  <TdFixed>
                    <UpdateButton
                      onClick={() => {
                        setModal({
                          show: true,
                          data: val,
                        });
                      }}
                    />
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <>
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {page.loading || !page.status || data.length === 0 ? (
        <DataStatus
          loading={page.loading}
          text={
            page.loading
              ? "Memuat data..."
              : !page.status
              ? "Data gagal dimuat, tidak dapat menampilkan data"
              : "Tidak ada data"
          }
        />
      ) : (
        <Table />
      )}

      {modal && <DataModal />}
    </>
  );
};

export default TabItem;
