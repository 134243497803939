import { useContext, useState } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  InfoItemVertical,
  InfoItemHorizontal,
  DatePicker,
  Input,
  SelectSearch,
  InputQtyDecimal,
  TextArea,
  InputCurrency,
} from 'components';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { TransferUpahWorkOrderApi } from 'api';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { DropdownContext } from '../context/DropdownContext';

export const ModalFormTransfer = ({
  data,
  toggle,
  isCreate,
  title,
  toggleSuccess,
  getLastQtyTransfer,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  return (
    <Modal show={true} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">
            {isCreate ? 'Tambah' : 'Detail'} {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          tgl_transfer_work_order_upah: isCreate
            ? undefined
            : data?.tgl_transfer_work_order_upah,
          no_transfer_work_order_upah: isCreate
            ? 'Pilih tanggal terlebih dahulu'
            : data?.no_transfer_work_order_upah,
          qty_transfer_work_order_upah: isCreate
            ? '0'
            : data?.qty_transfer_work_order_upah,
          keterangan_transfer: isCreate ? '' : data?.keterangan_transfer,
          qty_telah_ditransfer:
            data?.qty_transfer_work_order_upah ??
            data?.qty_telah_ditransfer_work_order_upah ??
            0,
          id_vendor: '',
          nama_proyek: data?.nama_proyek ?? '-',
          harga_satuan: data?.harga_satuan ?? 0,
        }}
        validationSchema={yup.object().shape({
          tgl_permintaan_work_order_upah: yup
            .date()
            .typeError('Masukkan Tanggal')
            .required('Masukkan Tanggal'),
          id_vendor: yup.string().required('Pilih Vendor'),
          qty_transfer_work_order_upah: yup
            .number()
            .max(
              parseFloat(data?.qty_permintaan_work_order_upah ?? 0) -
                parseFloat(data?.qty_transfer_work_order_upah ?? 0),
              `Qty. Transfer wajib diisi maksimal ${
                parseFloat(data?.qty_permintaan_work_order_upah ?? 0) -
                parseFloat(data?.qty_transfer_work_order_upah ?? 0)
              }`
            )
            .required('Qty. Transfer wajib diisi'),
          harga_satuan: yup.number().required('Masukkan Harga Satuan'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isCreate) {
            TransferUpahWorkOrderApi.save(values)
              .then(() => {
                if (getLastQtyTransfer?.length <= 1) {
                  history.replace(
                    '/transaksi/transfer-upah-work-order',
                    location.state
                  );
                } else {
                  toggleSuccess();
                  toggle();
                }
              })
              .catch(() => {
                setAlertConfig({
                  show: true,
                  text: 'Tambah data gagal',
                  variant: 'danger',
                });
              })
              .finally(() => setSubmitting(false));
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => {
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  });
                }}
              />

              <InfoItemHorizontal
                label="Tgl. Permintaan Work Order"
                text={
                  values?.tgl_permintaan_work_order_upah
                    ? DateConvert(
                        new Date(values.tgl_permintaan_work_order_upah)
                      ).detail
                    : '-'
                }
              />

              <InfoItemHorizontal
                label="No. Permintaan Work Order"
                text={values.no_permintaan_work_order_upah ?? '-'}
              />

              <InfoItemHorizontal
                label="Item Upah"
                text={values.nama_item ?? '-'}
              />

              <InfoItemHorizontal
                label="Satuan"
                text={values.nama_satuan ?? '-'}
              />

              <InfoItemHorizontal
                label="Qty. Permintaan Work Order"
                text={
                  DecimalConvert(values.qty_permintaan_work_order_upah ?? 0)
                    .getWithComa
                }
              />

              <InfoItemHorizontal
                label="Qty. Telah Ditransfer"
                text={
                  DecimalConvert(data?.qty_transfer_work_order_upah ?? 0)
                    .getWithComa
                }
              />

              <hr />

              <FormSection isCreate={isCreate} />
            </Modal.Body>

            {isCreate && (
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  text="Simpan"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const FormSection = ({ isCreate }) => {
  const { values, setFieldValue, errors, touched, handleChange } =
    useFormikContext();

  const { dropdownVendor } = useContext(DropdownContext);

  const getNomor = (tgl) => {
    TransferUpahWorkOrderApi.no_baru(tgl).then((res) => {
      setFieldValue('no_transfer_work_order_upah', res.data.data ?? '');
    });
  };

  if (!isCreate) {
    return (
      <>
        <Row>
          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="Tgl. Transfer Upah Work Order"
              text={
                values?.tgl_transfer_work_order_upah
                  ? DateConvert(new Date(values?.tgl_transfer_work_order_upah))
                      .detail
                  : '-'
              }
            />
          </Col>

          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="No. Transfer Upah Work Order"
              text={values?.no_transfer_work_order_upah ?? '-'}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical label="Vendor" text={values.nama_vendor ?? '-'} />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Qty. Transfer"
              text={`${
                DecimalConvert(values.qty_transfer_work_order_upah ?? 0)
                  .getWithComa
              } ${values.nama_satuan ?? ''}`}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Harga Satuan"
              text={
                RupiahConvert(String(parseFloat(values.harga_satuan ?? 0)))
                  .getWithComa
              }
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Total Harga"
              text={
                RupiahConvert(
                  String(
                    Math.round(
                      parseFloat(values.harga_satuan ?? 0) *
                        parseFloat(values.qty_transfer_work_order_upah ?? 0)
                    )
                  )
                ).getWithComa
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Keterangan Transfer"
              text={values.keterangan_transfer ?? '-'}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Row>
      <Col md={6}>
        <DatePicker
          label="Tgl. Transfer Upah Work Order"
          selected={
            values.tgl_transfer_work_order_upah
              ? new Date(values.tgl_transfer_work_order_upah)
              : ''
          }
          placeholderText="Pilih Tgl. Transfer Upah Work Order "
          onChange={(date) => {
            const tanggal = DateConvert(new Date(date)).default;

            setFieldValue('tgl_transfer_work_order_upah', tanggal);
            if (isCreate) getNomor(tanggal);
          }}
          error={
            Boolean(errors.tgl_transfer_work_order_upah) &&
            touched.tgl_transfer_work_order_upah
          }
          errorText={
            Boolean(errors.tgl_transfer_work_order_upah) &&
            touched.tgl_transfer_work_order_upah &&
            errors.tgl_transfer_work_order_upah
          }
        />
      </Col>

      <Col md={6}>
        <Input
          label="No. Transfer Upah Work Order"
          placeholder="No. Transfer Upah Work Order"
          value={values.no_transfer_work_order_upah}
          readOnly
        />
      </Col>

      <Col md={8}>
        <SelectSearch
          label="Vendor"
          placeholder="Pilih Vendor"
          defaultValue={dropdownVendor?.data?.find(
            (e) => e.value === values.id_vendor
          )}
          onChange={(val) => setFieldValue('id_vendor', val.value)}
          option={dropdownVendor?.data ?? []}
          loading={dropdownVendor?.loading}
          error={Boolean(errors.id_vendor) && touched.id_vendor}
          errorText={
            Boolean(errors.id_vendor) && touched.id_vendor && errors.id_vendor
          }
        />
      </Col>

      <Col md={4}>
        <InputQtyDecimal
          label="Qty. Transfer"
          value={values.qty_transfer_work_order_upah}
          onChange={(e) => {
            setFieldValue('qty_transfer_work_order_upah', e);
          }}
          error={
            Boolean(errors.qty_transfer_work_order_upah) &&
            touched.qty_transfer_work_order_upah
          }
          errorText={
            Boolean(errors.qty_transfer_work_order_upah) &&
            touched.qty_transfer_work_order_upah &&
            errors.qty_transfer_work_order_upah
          }
        />
      </Col>

      <Col>
        <InputCurrency
          label="Harga Satuan"
          className="text-right"
          value={parseFloat(values.harga_satuan)}
          onChange={(e) => {
            setFieldValue('harga_satuan', e);
          }}
          error={errors.harga_satuan && touched.harga_satuan && true}
          errorText={errors.harga_satuan}
        />
      </Col>

      <Col>
        <InputCurrency
          disabled
          label="Total Harga"
          className="text-right"
          placeholder="Total Harga"
          value={
            parseFloat(values.harga_satuan ?? 0) *
            parseFloat(values.qty_transfer_work_order_upah ?? 0)
          }
        />
      </Col>

      <Col md={12}>
        <TextArea
          label="Keterangan Transfer"
          name="keterangan_transfer"
          rows={4}
          placeholder="Keterangan Transfer"
          value={values.keterangan_transfer}
          onChange={handleChange}
          error={
            Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
          }
          errorText={
            Boolean(errors.keterangan_transfer) &&
            touched.keterangan_transfer &&
            errors.keterangan_transfer
          }
        />
      </Col>
    </Row>
  );
};
