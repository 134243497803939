import Services from "services"

class TransferUpahJobMixApi {
    page(params) {
        return Services.get("/transfer_produksi_upah/page", { params })
    }

    single(params) {
        return Services.get(`/transfer_produksi_upah/single`, { params })
    }

    produksi_page(params) {
        return Services.get("/transfer_produksi_upah/produksi_page", { params })
    }

    produksi_single(value) {
        return Services.get(`/transfer_produksi_upah/produksi_single?id_jobmix=${value}`)
    }

    jobmix_single(value) {
        return Services.get(`/transfer_produksi_upah/jobmix_single?id_jobmix=${value}`)
    }

    histori(value) {
        return Services.get(`/transfer_produksi_upah/histori?id_jobmix=${value}`)
    }

    getSinglePermintaan(params) {
        return Services.get("/transfer_produksi_upah/permintaan_single", { params })
    }

    no_baru(value) {
        return Services.get(`/transfer_produksi_upah/no_baru?tanggal=${value}`)
    }

    getDropdownVendor() {
        return Services.get("/dropdown/vendor")
    }

    save(data) {
        return Services.post("/transfer_produksi_upah", data)
    }
}

export default new TransferUpahJobMixApi();
