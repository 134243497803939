import { useEffect, useState } from 'react';
import { IoAddOutline, IoCheckmarkOutline } from 'react-icons/io5';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { TransferSubkonWorkOrderApi } from 'api';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { InfoSectionDetailWorkOrder, ModalFormTransfer } from './components';
import { withDropdownProvider } from './context/DropdownContext';

export const TambahTransferSubkonWorkOrder = withDropdownProvider(
  ({ setNavbarTitle }) => {
    const title = 'Transfer Subkon Work Order';
    const location = useLocation();
    const { id_work_order } = useParams();
    const history = useHistory();

    const [dataWorkOrder, setDataWorkOrder] = useState({});
    const [dataPermintaan, setDataPermintaan] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState({
      loading: true,
      status: false,
    });
    const [modalData, setModalData] = useState({
      data: {},
      isCreate: false,
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });

    useEffect(() => {
      setNavbarTitle(title);
      getData();

      // eslint-disable-next-line
    }, []);

    const getData = () => {
      setPage({
        loading: true,
        status: false,
      });

      TransferSubkonWorkOrderApi.singlePermintaan({ id_work_order })
        .then((res) => {
          const { permintaan, history, ...workOrder } = res?.data?.data;

          setDataWorkOrder(workOrder);
          setDataPermintaan(permintaan ?? []);
          setDataHistory(history ?? []);

          setPage({
            loading: false,
            status: true,
          });
        })
        .catch(() => {
          setPage({
            loading: false,
            status: false,
          });
        });
    };

    const toggleModal = () => setModal(!modal);

    const toggleSuccess = () => {
      setAlertConfig({
        show: true,
        text: 'Tambah data berhasil',
        variant: 'primary',
      });
      getData();
    };

    const getLastQtyTransfer = dataPermintaan?.filter(
      (val) =>
        parseFloat(val.qty_transfer_work_order_subkon ?? 0) <=
        parseFloat(val.qty_permintaan_work_order_subkon ?? 0),
    );

    return (
      <>
        <div className="text-right">
          <BackButton
            onClick={() =>
              history.replace(
                '/transaksi/transfer-subkon-work-order/list-permintaan-work-order',
                location.state,
              )
            }
          />
        </div>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => {
            setAlertConfig({
              ...alertConfig,
              show: false,
            });
          }}
        />

        {page.loading || !page.status ? (
          <DataStatus
            loading={page.loading}
            text={
              page.loading
                ? 'Memuat data...'
                : 'Data gagal dimuat, tidak dapat menampilkan data'
            }
          />
        ) : (
          <>
            <InfoSectionDetailWorkOrder data={dataWorkOrder} />

            <div className="font-weight-bold" style={{ fontSize: '14px' }}>
              List Data Permintaan Work Order
            </div>

            <Table>
              <THead>
                <Tr>
                  <ThFixed>No</ThFixed>
                  <ThFixed>Informasi Permintaan Work Order</ThFixed>
                  <Th>Item Upah</Th>
                  <Th width={100}>Satuan</Th>
                  <Th width={120}>Qty. Permintaan Work Order</Th>
                  <Th width={120}>Total Qty. Telah Ditransfer</Th>
                  <ThFixed>Aksi</ThFixed>
                </Tr>
              </THead>

              <TBody>
                {dataPermintaan?.length > 0 ? (
                  dataPermintaan.map((val, index) => {
                    const qty_permintaan_work_order = parseFloat(
                      val.qty_permintaan_work_order_subkon ?? 0,
                    );

                    const qty_transfer = parseFloat(
                      val.qty_transfer_work_order_subkon ?? 0,
                    );

                    const notAvailable = Boolean(
                      qty_transfer >= qty_permintaan_work_order,
                    );

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>
                          <div>
                            {val.tgl_permintaan_work_order_subkon
                              ? DateConvert(
                                  new Date(val.tgl_permintaan_work_order_subkon),
                                ).defaultDMY
                              : '-'}
                          </div>
                          <div>{val.no_permintaan_work_order_subkon ?? '-'}</div>
                        </Td>
                        <Td>{val.nama_item ?? '-'}</Td>
                        <Td>{val.nama_satuan ?? '-'}</Td>
                        <Td className="text-right">
                          {
                            DecimalConvert(qty_permintaan_work_order)
                              .getWithComa
                          }
                        </Td>
                        <Td className="text-right">
                          {DecimalConvert(qty_transfer).getWithComa}
                        </Td>
                        <TdFixed>
                          <ActionButton
                            text={
                              notAvailable ? (
                                <IoCheckmarkOutline size={12} />
                              ) : (
                                <IoAddOutline size={12} />
                              )
                            }
                            variant={notAvailable ? 'success' : 'primary'}
                            className="my-1"
                            size="sm"
                            onClick={() => {
                              if (!notAvailable) {
                                setModalData({
                                  data: val,
                                  isCreate: true,
                                });
                                toggleModal();
                              } else {
                                return null;
                              }
                            }}
                          />
                        </TdFixed>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={8}>
                      <DataStatus text="Tidak ada data" />
                    </Td>
                  </Tr>
                )}
              </TBody>
            </Table>

            <div className="font-weight-bold" style={{ fontSize: '14px' }}>
              History {title}
            </div>
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No</ThFixed>
                  <ThFixed>Informasi Transfer Subkon Work Order</ThFixed>
                  <ThFixed>Informasi Permintaan Work Order</ThFixed>
                  <Th>Item Upah</Th>
                  <Th width={100}>Qty. Transfer</Th>
                  <Th width={100}>Vendor</Th>
                  <Th width={100}>Harga Satuan</Th>
                  <Th width={100}>Total Harga</Th>
                  <ThFixed>Aksi</ThFixed>
                </Tr>
              </THead>

              <TBody>
                {dataHistory && dataHistory.length > 0 ? (
                  dataHistory.map((val, index) => {
                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>
                          <div>
                            {val.tgl_transfer_work_order_subkon
                              ? DateConvert(
                                  new Date(val.tgl_transfer_work_order_subkon),
                                ).defaultDMY
                              : '-'}
                          </div>
                          <div>{val.no_transfer_work_order_subkon ?? '-'}</div>
                        </Td>
                        <Td>
                          <div>
                            {val.tgl_permintaan_work_order_subkon
                              ? DateConvert(
                                  new Date(val.tgl_permintaan_work_order_subkon),
                                ).defaultDMY
                              : '-'}
                          </div>
                          <div>{val.no_permintaan_work_order_subkon ?? '-'}</div>
                        </Td>
                        <Td>{val.nama_item ?? '-'}</Td>
                        <Td className="text-right">
                          {
                            DecimalConvert(
                              val.qty_transfer_work_order_subkon ?? 0,
                            ).getWithComa
                          }
                        </Td>
                        <Td>{val.nama_vendor ?? '-'}</Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(
                              String(parseFloat(val.harga_satuan ?? 0)),
                            ).getWithComa
                          }
                        </Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(
                              String(
                                Math.round(
                                  parseFloat(val.harga_satuan ?? 0) *
                                    parseFloat(
                                      val.qty_transfer_work_order_subkon ?? 0,
                                    ),
                                ),
                              ),
                            ).getWithComa
                          }
                        </Td>

                        <TdFixed>
                          <ReadButton
                            onClick={() => {
                              setModalData({
                                data: val,
                                isCreate: false,
                              });
                              toggleModal();
                            }}
                          />
                        </TdFixed>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={10}>
                      <DataStatus text="Tidak ada data" />
                    </Td>
                  </Tr>
                )}
              </TBody>
            </Table>
          </>
        )}

        {modal && (
          <ModalFormTransfer
            data={modalData.data}
            toggle={toggleModal}
            title={title}
            isCreate={modalData.isCreate}
            toggleSuccess={toggleSuccess}
            getLastQtyTransfer={getLastQtyTransfer}
          />
        )}
      </>
    );
  },
);
