import {
  DataStatus,
  TBody,
  THead,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from 'components';
import { useFormikContext } from 'formik';
import React from 'react';
import { DateConvert, DecimalConvert } from 'utilities';

export const TableSection = () => {
  const { values } = useFormikContext();
  return (
    <div className="mt-3">
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-nowrap">Grup Aset</ThFixed>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Item Aset Yang Diminta</Th>
            <Th>Keperluan</Th>
            <ThFixed>Informasi Penerimaan Aset</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {values?.detail && values?.detail.length > 0 ? (
            values?.detail?.map((val, ind) => (
              <Tr>
                <Td>{ind + 1}</Td>
                <Td>{val?.nama_grup_aset ?? '-'}</Td>
                <Td>{val?.nama_kategori_aset ?? '-'}</Td>
                <Td>{val?.nama_jenis_aset ?? '-'}</Td>
                <Td>{val?.nama_aset ?? '-'}</Td>
                <Td className="text-capitalize">{val?.keperluan ?? '-'}</Td>
                <Td>
                  <div>
                    {val?.tgl_penerimaan_aset
                      ? DateConvert(new Date(val.tgl_penerimaan_aset)).custom
                      : '-'}
                  </div>
                  <div>{val?.no_penerimaan_aset ?? '-'}</div>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="7">
                <DataStatus text="Data Tidak Ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
