import { createContext } from 'react';
import { TransferUpahWorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const dropdownUpah = useQuery(
    ['dropdown', 'upah'],
    () =>
      TransferUpahWorkOrderApi.getDropdownUpah().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_buaso,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      TransferUpahWorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownSubkon = useQuery(
    ['dropdown', 'subkon'],
    () =>
      TransferUpahWorkOrderApi.getDropdownSubkon().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_buaso,
          label: val.nama_item,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownVendor = useQuery(
    ['dropdown', 'vendor'],
    () =>
      TransferUpahWorkOrderApi.getDropdownVendor().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_vendor,
          label: val.nama_vendor,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  return (
    <DropdownContext.Provider
      value={{
        dropdownUpah,
        dropdownAset,
        dropdownVendor,
        dropdownSubkon
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const withDropdownProvider = (Component) => (props) => {
  return (
    <DropdownProvider>
      <Component {...props} />
    </DropdownProvider>
  );
};
